
export const getOrderStatusColor = (status) => {
    switch (status) {
        case 'PROCESS':
            return 'bg-info/10 text-info';
        case 'ACCEPTED':
            return 'bg-success/10 text-success';
        case 'PLACED':
            return 'bg-warning/10 text-warning';
        case 'CANCELLED':
            return 'bg-danger/10 text-danger';
        case 'PREPARING':
            return 'bg-primary/10 text-primary';
        case 'TRANSIT':
            return 'bg-secondary/10 text-secondary';
        case 'DELIVERED':
            return 'bg-success/10 text-success';
        case 'FAILED':
            return 'bg-danger/10 text-danger';
        case 'PENDING':
            return 'bg-warning/10 text-warning';
        case 'PAID':
            return 'bg-success/10 text-success';
        default:
            return 'bg-gray-100 text-gray-800';
    }
}


export const ORDER_STATUS_TAKE_AWAY_OPTIONS = [
    { value: 'ACCEPTED', label: 'ACCEPTED' },
    { value: 'PREPARING', label: 'PREPARING' },
    { value: 'READY', label: 'READY' },
    { value: 'COMPLETED', label: 'COMPLETED' },
    { value: 'CANCELLED', label: 'CANCELLED' },
]

export const ORDER_STATUS_DELIVERY_OPTIONS = [
    { value: 'ACCEPTED', label: 'ACCEPTED' },
    { value: 'PREPARING', label: 'PREPARING' },
    { value: 'TRANSIT', label: 'TRANSIT' },
    { value: 'DELIVERED', label: 'DELIVERED' },
    { value: 'CANCELLED', label: 'CANCELLED' },
]


export const ORDER_STATUS_LIST = {
    PROCESS: "INITIATE",
    ACCEPTED: "ACCEPTED",
    PLACED: "PLACED",
    PREPARING: "PREPARING",
    TRANSIT: "TRANSIT",
    READY: "READY",
    COMPLETED: "COMPLETED",
    DELIVERED: "DELIVERED",
    CANCELLED: "CANCELLED",
    FAILED: "FAILED",
    PENDING: "PENDING",
    PAID: "PAID",
    COD: "COD",
    REFUND_INITIATED: "REFUND INITIATED",
    REFUND_COMPLETED: "REFUND COMPLETED"
}

export const ORDER_PAYMENT_STATUS = [
    { value: 'REFUND_INITIATED', label: 'REFUND INITIATED' },
    { value: 'REFUND_COMPLETED', label: 'REFUND COMPLETED' },
]

export const ORDER_PAYMENT_STATUS_COD = [{ value: 'PAID', label: 'PAID' },]

export const SERVICES = {
    DELIVERY: "DELIVERY",
    TAKEAWAY: "TAKEAWAY",
    GLOBAL_DELIVERY: "GLOBAL_DELIVERY",
    GLOBAL_TAKEAWAY: "GLOBAL_TAKEAWAY"
}

export const PAYMENT_MODES = {
    COD: "COD",
    ONLINE: "ONLINE",
}
import React from 'react';
import PropTypes from 'prop-types';

const AddProductButton = ({ handleBack, handleNext, loading, buttonText ="Next" }) => {
  return (
    <div className="px-6 py-4 border-t border-dashed dark:border-defaultborder/10 sm:flex justify-end">
      <button
        type="button"
        className="ti-btn ti-btn-primary !font-medium m-1"
        onClick={handleBack}
        disabled={loading} // Disable button when loading
      >
        Previous
      </button>
      <button
        type="submit"
        className="ti-btn ti-btn-success !font-medium m-1"
        onClick={handleNext}
        disabled={loading} // Disable button when loading
      >
        {loading ? (
          <>
            <span className="me-2">Loading</span>
            <span className="loading">
              <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
            </span>
          </>
        ) : (
          buttonText
        )}
      </button>
    </div>
  );
};

AddProductButton.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  loading: PropTypes.bool, // Add loading prop type
  buttonText: PropTypes.string
};


export default AddProductButton;
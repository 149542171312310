import { FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_FAILURE } from './actions'

const initialState = {
  productList: [],
  error: null,
}

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        productList: action.payload,
        error: null,
      }
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

// User types
export const MAP_USER_TYPES = {
  HUB_ADMIN: 'Hub Admin',
  PULSE_ADMIN: 'Pulse Admin',
  PULSE_SUB_ADMIN: 'Pulse Subadmin',
  HUB_SUB_ADMIN: 'Hub Subadmin',
  HUB_PULSE_SUBADMIN: 'Hub Pulse Subadmin',
}
export const MAP_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PENDING: 'Pending',
}

import { getCompanyCollection, getCompanyCollectionDoc } from '../../Models/catalog/catalog'

const getPaginatedData = async (id, collection, pageSize, lastVisible, orderBy = 'createdAt') => {
  const result = await getCompanyCollection(id, collection, pageSize, lastVisible, orderBy)
  return {
    data: result.data,
    lastVisible: result.lastVisible,
  }
}

const getSingleCompanyDoc = async (id, collection, document) => {
  const result = await getCompanyCollectionDoc(id, collection, document)
  return {
    data: result.data,
  }
}

export { getPaginatedData, getSingleCompanyDoc }

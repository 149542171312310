import { collection ,serverTimestamp,addDoc, updateDoc, Timestamp, doc} from "firebase/firestore"
import { db } from '../../firebase/firebaseapi'
import { COLLECTIONS } from "@hub/constants/constants"
import { PROMOTIONS } from "@common/src/constants/constants"

export const addPromoCodes = async(data,companyId) => {
    try {
        const promoCodeRef = collection(db, `${COLLECTIONS.COMPANIES}/${companyId}/${PROMOTIONS.PROMOCODES}`)
        if (data.rules.startDate) {
            data.rules.startDate = Timestamp.fromDate(new Date(data.rules.startDate))
          }
          if (data.rules?.endDate) {
            data.rules.endDate = Timestamp.fromDate(new Date(data.rules.endDate))
          }
        
        const formData={
            ...data,
            createdAt: serverTimestamp(),

        }
        await addDoc(promoCodeRef, formData)
    } catch (error) {
        console.error('Error adding promocodes: ', error)
    }
}


export const updatePromoCodes=async(id,data,companyId)=>{
    try{
        const promoCodeRef = doc(db, `${COLLECTIONS.COMPANIES}/${companyId}/${PROMOTIONS.PROMOCODES}/${id}`)
        if (data.rules.startDate) {
            data.rules.startDate = Timestamp.fromDate(new Date(data.rules.startDate))
          }
          if (data.rules?.endDate) {
            data.rules.endDate = Timestamp.fromDate(new Date(data.rules.endDate))
          }
        const formData={
            ...data,
            updatedAt: serverTimestamp(),

        }
        await updateDoc(promoCodeRef, formData)
    }catch(error){
        console.error('Error updating Promo codes: ', error)
    }
}
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client';
import { getAuth } from 'firebase/auth';

const authLink = new ApolloLink(async (operation, forward) => {
    const auth = getAuth();
    const token = auth.currentUser.accessToken;
    if (token) {
        operation.setContext({
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
        });
    }
    return forward(operation);
});

const httpLink = new HttpLink({ uri: import.meta.env.VITE_GRAPHQL_URL });

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authLink, httpLink),
});

export default client;

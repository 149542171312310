
import LocationTable from '../../../components/locationTable/LocationTable'

const LocationDetails = () => {
  return (
      <LocationTable  />
  )
}

export default LocationDetails

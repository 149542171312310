import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SelectBox from '@common/src/common/selectbox/selectbox';
import AddProductButton from '../../../components/common/buttons/AddProductButton';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import InputBox from '@common/src/common/inputbox/inputbox';
import { getCatagories } from '@common/src/service/firebase/company';
import { useSelector } from 'react-redux';

const BasicDetials = ({ handleNext, setProductData, productData, isEdit, setEditProductData, editProductData }) => {
  const [initialValues, setInitialValues] = useState({
    name: {},
    description: {},
    type: '',
    category: '',
    posId: '',
  });
  const [catagories, setCatagories] = useState([]);
  const { selectedCompany } = useSelector((state) => state.auth);

  useEffect(() => {
    setInitialValues({
      name: productData?.name || {},
      description: productData?.description || {},
      type: productData?.type || '',
      category: productData?.categoryId || '',
      posId: productData?.posId || '',
    });
  }, [productData]);

  const handleSubmit = async (values) => {
    const changedValues = {};
  
    // Compare current values with initial values to find changes
    for (const key in values) {
      if (values[key] !== initialValues[key]) {
        changedValues[key] = values[key];
      }
    }
  
    if (isEdit) {
      // Update only changed fields in editProductData
      setEditProductData((prevData) => ({
        ...prevData,
        ...changedValues,
        categoryId:parseInt(values.category), // Ensure categoryId is updated
      }));
      setProductData((prevData) => ({
        ...prevData,
        ...editProductData,
      }));
    } else {
      setProductData((prevData) => ({
        ...prevData,
        ...changedValues,
        categoryId:parseInt(values.category), // Ensure categoryId is updated
      }));
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.object().shape({
      en: Yup.string().required('English name is required'),
      ar: Yup.string().required('Arabic name is required'),
    }),
    description: Yup.object().shape({
      en: Yup.string().required('English description is required'),
      ar: Yup.string().required('Arabic description is required'),
    }),
    type: Yup.string().required('Food type is required'),
    category: Yup.string().required('Category is required'),
    posId: Yup.string().required('POS ID is required'),
  });


  const getCatagoriesData = async (companyId) => {
    const result = await getCatagories(companyId);
    const catagoriesData = result.map((category) => ({
      value: category.id,
      label: category.name.en,
    }));
    setCatagories(catagoriesData);
  }
  useEffect(() => {
    getCatagoriesData(selectedCompany?.value);
  },[selectedCompany?.value])
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleSubmit(values);
                  handleNext();
                  setSubmitting(false);
                }}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form>
                    <div className="grid grid-cols-2 gap-6">
                      {/* Product Name */}
                      <div className="xl:col-span-1 col-span-12">
                        <MultiLanguageInput
                          type="input"
                          label="Product Name"
                          id="product-name-add"
                          name="name"
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          values={values}
                          required
                        />
                      </div>
                      {/* Category */}
                      <div className="xl:col-span-1 col-span-12">
                        <SelectBox
                          id="product-category-add"
                          label="Category"
                          options={catagories}
                          value={catagories.find(option => option?.value === parseInt(values?.category)) || null} // Set the current value
                          name="category"
                          onChange={(e) => setFieldValue('category', e.value)}
                          required
                        />
                        <ErrorMessage name="category" component="div" style={{ color: 'red' }} />
                      </div>
                      {/* Description */}
                      <div className="xl:col-span-1 col-span-12">
                        <MultiLanguageInput
                          type="textarea"
                          label="Description"
                          id="product-description-add"
                          name="description"
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          values={values}
                          required
                        />
                      </div>
                      {/* Food Type Radio Button */}
                      <div className="xl:col-span-1 col-span-12">
                        <label className="form-label" htmlFor='foodType'>Food Type<span style={{ color: 'red' }}>*</span></label>
                        <div className="form-check form-check-md flex items-center">
                          <Field type="radio" name="type" value="VEG" className="form-check-input" id="radio-lg" />
                          <label className="form-check-label" htmlFor="radio-lg">
                            Veg
                          </label>
                        </div>
                        <div className="form-check form-check-md flex items-center mt-3">
                          <Field
                            type="radio"
                            name="type"
                            value="NON_VEG"
                            className="form-check-input"
                            id="radio-md"
                          />
                          <label className="form-check-label" htmlFor="radio-md">
                            Non-Veg
                          </label>
                        </div>
                        <ErrorMessage name="type" component="div" style={{ color: 'red' }} />
                      </div>
                      {/* POS ID */}
                      <div className="xl:col-span-1 col-span-12">
                        <label htmlFor="posId" className="form-label">POS ID<span style={{ color: 'red' }}>*</span></label>
                        <InputBox
                          placeholder="POS ID"
                          type="text"
                          id="posId"
                          name="posId"
                          value={values.posId}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(/^\s+/, ''); // Remove leading spaces
                            setFieldValue('posId', sanitizedValue); // Update Formik's value
                          }}
                          required
                        />
                        <ErrorMessage name="posId" component="div" style={{ color: 'red' }} />
                      </div>
                    </div>
                    <AddProductButton />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BasicDetials.propTypes = {
  handleNext: PropTypes.func.isRequired,
  setProductData: PropTypes.func.isRequired,
  productData: PropTypes.object,
  isEdit: PropTypes.bool,
  setEditProductData: PropTypes.func,
  editProductData: PropTypes.object,
};

export default BasicDetials;
import { useEffect, useMemo, useRef, useState } from 'react'
import { collection } from 'firebase/firestore'

import TableList from '@common/src/common/Table/TableList'
import PageHeader from '@common/src/pageheader/pageheader'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import Toggle from '@common/src/common/Toggle/Toggle'
import { useToast } from '@common/src/common/Toast/ToastProvider'

import { db } from '@hub/firebase/firebaseapi'
import { CATALOG } from '@hub/constants/constants'
import ModifierGroupForm from './modifierGroupForm'
import { useSelector } from 'react-redux'
import { COLLECTIONS, COMPANY_ID } from '@common/src/constants/constants'
import { modifierGroupAction } from '@common/src/service/cloud/catalog'

const getColumns = ({ onEditClick, onStatusToggleClick, modifierGroupDisabled }) => [
  {
    title: 'Name',
    dataIndex: 'modGroupName.en',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => <>{text}</>,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <div className="space-x-1 rtl:space-x-reverse flex">
        <button
          onClick={() => onEditClick(record)}
          aria-label="button"
          type="button"
          className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
        >
          <i className="ri-pencil-line"></i>
        </button>
        <Toggle
          label=""
          value={record.status === 'ACTIVE'}
          onChange={() => onStatusToggleClick(record)}
          disabled={modifierGroupDisabled}
        />
      </div>
    ),
  },
]

const pagination = { pageSize: 20 }

const ModifierGroup = () => {
  const tableRef = useRef(null)
  const { selectedCompany } = useSelector((state) => state.auth)
  const [modifierGroupDisabled, setModifierGroupDisabled] = useState(false);
  const [dbRef, setDbRef] = useState(null)
  const [data, setData] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
  const toast = useToast()

  // Centralized function to handle status update
  const handleStatusUpdate = async (status, record) => {
    setModifierGroupDisabled(true)
    const formData = { ...record, status, ...(record?.id && { id: parseInt(record?.id) }) }
    try {
      await modifierGroupAction(formData, true)
      toast.success(`Modifier group status updated to ${status.toLowerCase()}`)
      tableRef.current.refreshCurrentPage()
    } catch (error) {
      toast.error('Error updating Modifier group')
    }
    finally{
      setModifierGroupDisabled(false)
    }
  }

  useEffect(() => {
    if (selectedCompany) {
      setDbRef(collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${CATALOG.MODIFIER_GROUP_COLLECTION}`))
    }
  }, [selectedCompany])

  const onEditClick = (data) => {
    setData(data)
    setIsEdit(true)
    openOffCanvas('addModifierGroup', 'Edit Modifier Group')
  }
   // Updated status toggle logic
   const onStatusToggleClick = (record) => {
    const newStatus = record.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    handleStatusUpdate(newStatus, record)
  }


  const columns = useMemo(() => getColumns({ onEditClick, onStatusToggleClick, modifierGroupDisabled }), [onEditClick, onStatusToggleClick, modifierGroupDisabled])
 
  const onComplete = () => {
    if (tableRef.current) {
      tableRef.current.refreshCurrentPage()
      closeOffCanvas()
    }
  }

  const onClose = () => closeOffCanvas(false)

  const onAddClick = () => {
    setData(null)
    setIsEdit(false)
    openOffCanvas('addModifierGroup', 'Add Modifier Group')
  }

  return (
    <>
      <PageHeader currentpage="Modifier Groups" activepage="Catalog Management" mainpage="catalog" />
      <TableList
        name="Modifier Groups"
        columns={columns}
        dbRef={dbRef}
        pagination={pagination}
        tools={{
          btnName: 'Add Modifier Group',
          btnClick: onAddClick,
        }}
        ref={tableRef}
      />
      <FloatingCanvas onClose={onClose} isVisible={isVisible} close={closeOffCanvas} title={offCanvasTitle}>
        {activeComponent === 'addModifierGroup' && (
          <ModifierGroupForm data={data} isEdit={isEdit} onComplete={onComplete} companyId={selectedCompany?.value || COMPANY_ID} />
        )}
      </FloatingCanvas>
    </>
  )
}

export default ModifierGroup

import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { getAuth, confirmPasswordReset, checkActionCode } from 'firebase/auth'

import logger from '@common/src/lib/logger'
import { useToast } from '@common/src/common/Toast/ToastProvider'

import desktopLogo from '../assets/images/brand-logos/desktop-logo.png'
import { activateUser } from '@hub/service/firebase/user'

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      'Password must contain at least 6 characters, one uppercase, one lowercase, one number, and one special character'
    )
    .required('Password is required'),
})
const Join = ({ type = 'join' }) => {
  const [passwordshow1, setPasswordshow1] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()
  const [searchParams] = useSearchParams()
  const auth = getAuth()
  const oobCode = searchParams.get('oobCode')

  const join = async (v) => {
    if (!oobCode || !v.password) {
      toast.error('Missing oobCode or password')
      return
    }

    try {
      setLoading(true)
      await confirmPasswordReset(auth, oobCode, v.password)
      if (type === 'join') {
        await activateUser(userEmail)
      }
      setLoading(false)
      toast.success(`Welcome to the Chicking Admin Portal! Your password has been set successfully.`)
      logger.log('Password has been reset successfully!')
      navigate('/')
    } catch (error) {
      setLoading(false)
      toast.error(
        `An error occurred while setting your password. Please try again or contact support if the issue persists.`
      )
    }
  }
  useEffect(() => {
    if (oobCode) {
      checkActionCode(auth, oobCode)
        .then((info) => {
          setUserEmail(info.data.email)
        })
        .catch((error) => {
          toast.error('The link has expired or is invalid. Please request a new password reset link.')
          navigate('/')
        })
    } else {
      navigate('/')
    }
  }, [oobCode, auth])

  // if no
  return (
    <div className="container">
      <div className="flex justify-center authentication authentication-basic items-center h-full text-defaultsize text-defaulttextcolor">
        <div className="grid grid-cols-12">
          <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
          <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-8 col-span-12">
            <div className="my-[2.5rem] flex justify-center">
              <Link to={`${import.meta.env.BASE_URL}dashboard`}>
                <img src={desktopLogo} alt="logo" className="desktop-logo" />
                <img src={desktopLogo} alt="logo" className="desktop-dark" />
              </Link>
            </div>
            <div className="box">
              <div className="box-body !p-[3rem]">
                <Formik initialValues={{ password: '' }} validationSchema={validationSchema} onSubmit={join}>
                  {({ setFieldValue, values, handleChange, handleBlur, errors, touched, isSubmitting }) => (
                    <Form>
                      <p className="h5 font-semibold mb-2 text-center">
                        {type === 'join' ? 'Join' : 'Reset your password'}
                      </p>

                      <div className="grid grid-cols-12 gap-y-4">
                        <div className="xl:col-span-12 col-span-12">
                          <label htmlFor="signin-username" className="form-label text-default">
                            Email
                          </label>
                          <input
                            disabled
                            type="email"
                            name="email"
                            className="form-control form-control-lg w-full !rounded-md"
                            value={userEmail}
                            id="signin-username"
                            placeholder="Email"
                          />
                        </div>
                        <div className="xl:col-span-12 col-span-12">
                          <label htmlFor="signup-password" className="form-label text-default">
                            New Password
                          </label>
                          <div className="input-group">
                            <input
                              type={passwordshow1 ? 'text' : 'password'}
                              className="form-control form-control-lg !rounded-e-none"
                              id="signup-password"
                              placeholder="New Password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              //onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <button
                              onClick={() => setPasswordshow1(!passwordshow1)}
                              aria-label="button"
                              type="button"
                              className="ti-btn ti-btn-light !rounded-s-none !mb-0"
                              id="button-addon2"
                            >
                              <i className={`${passwordshow1 ? 'ri-eye-line' : 'ri-eye-off-line'} align-middle`}></i>
                            </button>
                          </div>
                          {touched.password && errors.password && (
                            <label
                              htmlFor={'signup-password'}
                              className="mt-1 text-[0.75rem] opacity-[0.8] !mb-0"
                              style={{ color: 'red' }}
                            >
                              {errors.password}
                            </label>
                          )}
                        </div>
                        <div className="xl:col-span-12 col-span-12 grid mt-2">
                          <button
                            type="submit"
                            disabled={loading}
                            className="ti-btn ti-btn-primary !bg-primary !text-white !font-medium"
                          >
                            {loading ? <span className="me-2">Joining </span> : `Join`}
                            {loading && (
                              <span className="loading">
                                <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="text-center">
                        <p className="text-[0.75rem] text-[#8c9097] dark:text-white/50 mt-4">
                          Dont have an account?{' '}
                          <Link to={`${import.meta.env.BASE_URL}`} className="text-primary">
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
        </div>
      </div>
    </div>
  )
}

Join.propTypes = {
  type: PropTypes.string,
}
export default Join

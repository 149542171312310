import InputBox from '@common/src/common/inputbox/inputbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { PROMO_CODE_TYPES, STATUS_OPTIONS } from '@hub/constants/constants'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { getCategories } from '@common/src/service/firebase/categories'
import MultiInputBox from '@common/src/common/multiInputBox/MultiInputBox'
import { GET_COMPANY_STORES } from '@common/src/lib/graphql/query/store'
import { useQuery } from '@apollo/client'
import { addPromoCodes, updatePromoCodes } from '@hub/service/firebase/promoCodes'
import debounce from "lodash.debounce";
import PropTypes from 'prop-types';
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'

const validationSchema = Yup.object({
  name: Yup.string().required('Promo code name is required'),
  code: Yup.string().required('Promo code is required'),
  type: Yup.string().required('Promo code type is required'),
  value: Yup.number().required('Discount value is required').min(1, 'Discount value must be greater than zero'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date().required('End date is required'),
  maxUsesPerCustomer: Yup.number().required('Maximum number of uses count is required').min(1, 'Maximum number of uses count must be greater than zero'),
  minOrderValue: Yup.number().required('Minimum order value is required').min(1, 'Minimum order value must be greater than zero'),
  maxDiscount: Yup.number().required('Maximum discount is required').min(1, 'Maximum discount must be greater than zero'),
  validStores: Yup.array().min(1, 'At least one store must be selected').required('At leaset one store must be selected'),
  productCategory: Yup.array().min(1, 'At least one category must be selected').required('At leaset one service must be selected'),
  termsandconditions: Yup.string()
  .required('Terms and condition of usage is required')
  .min(10, 'Terms and condition of usage must be at least 10 characters long')
  .max(500, 'Terms and condition of usage cannot exceed 500 characters'),
  maxDiscountText: Yup.object()
    .shape({
      en: Yup.string().required('English Text is required'),
      ar: Yup.string().required('Arabic Text is required'),
    })
    .required('Maximum discount text is required'),
  description: Yup.object()
    .shape({
      en: Yup.string().required('English description is required'),
      ar: Yup.string().required('Arabic description is required'),
    })
    .required('Description is required'),


})

const PromoCodeForm = ({ id, promoData, isEdit, onComplete }) => {
  const [stores, setStores] = useState([])
  const [categories, setCategories] = useState([])
  const { selectedCompany } = useSelector((state) => state.auth)
  const [initialValues, setInitialValues] = useState({})

  const getInitialData = async () => {
    setInitialValues({
      name: promoData?.name || "",
      code: promoData?.code || '',
      type: promoData?.type || '',
      value: promoData?.value || '',
      status: promoData?.status ? { value: promoData?.status, label: promoData?.status } : { value: 'ACTIVE', label: 'ACTIVE' },
      startDate: promoData?.rules?.startDate ? new Date(parseFloat(promoData?.rules?.startDate?.seconds * 1000)).toISOString().slice(0, 16) : '',
      endDate: promoData?.rules?.endDate ? new Date(parseFloat(promoData?.rules?.endDate?.seconds * 1000)).toISOString().slice(0, 16) : '',
      maxUsesPerCustomer: promoData?.maxUsesPerCustomer || 0,
      minOrderValue: promoData?.rules.minOrderValue || 0,
      maxDiscount: promoData?.rules.maxDiscount || 0,
      validStores: promoData?.rules.validStores || [],
      productCategory: promoData?.categoryId || [],
      termsandconditions: promoData?.termsandconditions || "",
      maxDiscountText:promoData?.promoDetails?.maxDiscountText || {},
      description:promoData?.promoDetails?.maxDiscountText || {}

    })
  }
  useEffect(() => {
    getInitialData()
  }, [])


  // getting stores


  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    totalPages: 0
  });

  const [searchQuery, setSearchQuery] = useState('')
  const { data, refetch, } = useQuery(GET_COMPANY_STORES, {
    variables: {
      companyId: selectedCompany?.value,
      limit: pagination.limit,
      offset: pagination.offset,
      search: searchQuery || undefined
    },
  });

  useEffect(() => {
    if (data) {
      setStores(
        data.storeListQuery.rows.map((item) => ({
          value: item.id,
          label: item.name?.en || "",
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    const debounceRefetch = debounce(() => {
      refetch({
        companyId: selectedCompany.value,
        limit: pagination.limit,
        offset: pagination.offset,
        search: searchQuery || undefined,
      });
    }, 300);

    debounceRefetch();

    return () => {
      debounceRefetch.cancel();
    };
  }, [searchQuery, pagination, selectedCompany?.value, refetch]);


  const handleInputChange = (inputValue) => {
    setSearchQuery(inputValue);
    setPagination((prev) => ({ ...prev, offset: 0 }));
    refetch();
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchCategories = getCategories(selectedCompany)

        const [categories,] = await Promise.all([
          fetchCategories,
        ])
        // Process roles
        const getCategoriesData = categories?.map((doc) => ({
          value: doc?.categoryId,
          label: doc?.name?.en
        }))
        setCategories(getCategoriesData)

      } catch (error) {
        console.error('Error fetching data: ', error)
      }
    }
    fetchData()
  }, [])


  const handleSubmit = (values, actions) => {
   
    const formData = {
      name: values.name,
      code: values.code,
      type: values.type,
      value: values.value,
      status: values.status.value,
      maxUsesPerCustomer: values.maxUsesPerCustomer,
      categoryId: (values.productCategory),
      termsandconditions: values.termsandconditions,
      rules: {
        startDate: values.startDate,
        endDate: values.endDate,
        minOrderValue: values.minOrderValue,
        maxDiscount: values.maxDiscount,
        validStores: (values.validStores),
      },
      promoDetails: {
        description: values.description,
        maxDiscountText: values.maxDiscountText
      },
      companyId: selectedCompany.value,
    }
    addPromoCodes(formData, selectedCompany.value);
    actions?.resetForm();
    actions?.setSubmitting(false);
    HSOverlay.close(`#${id}`);
    onComplete?.();
  }

  const updatePromoCode = async (values, actions) => {
    console.log(values);
    
    const formData = {
      name: values.name,
      code: values.code,
      type: values.type,
      value: values.value,
      status: values.status.value,
      maxUsesPerCustomer: values.maxUsesPerCustomer,
      categoryId: values.productCategory,
      termsandconditions: values.termsandconditions,
      rules: {
        startDate: values.startDate,
        endDate: values.endDate,
        minOrderValue: values.minOrderValue,
        maxDiscount: values.maxDiscount,
        validStores: values.validStores,
      },
      promoDetails: {
        description: values.description,
        maxDiscountText: values.maxDiscountText
      },
      companyId: selectedCompany.value,
    }
    updatePromoCodes(promoData.id, formData, selectedCompany.value)
    actions?.resetForm();
    actions?.setSubmitting(false);
    HSOverlay.close(`#${id}`);
    onComplete?.();
  }

  return (
    <OffCanvasForm
      enableReinitialization
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={isEdit ? updatePromoCode : handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
      autoClose={false}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <div className="grid grid-cols-12 gap-6 ">
          <div className="xl:col-span-12 col-span-12">
            <div className="box">
              <div className="box-body add-products !p-0">
                <div className="grid grid-cols-1 gap-6">
                  <InputBox
                    type="text"
                    id="name"
                    name="name"
                    label="Promo Code Name"
                    placeholder="Enter Promo Code Name"
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    required = {true}
                    errorText={touched.name && errors.name ? errors.name : ''}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <MultiLanguageInput
                    type="input"
                    label="Description"
                    id="description"
                    name="description"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    required = {true}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <MultiLanguageInput
                    type="input"
                    label="Maximum Discount Text"
                    id="maxDiscountText"
                    name="maxDiscountText"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    required = {true}
                  />
                </div>
                <div className="col-span-12">
                  <SelectBox
                    id="type"
                    label="Choose Promo Code Type"
                    options={PROMO_CODE_TYPES}
                    name="type"
                    value={PROMO_CODE_TYPES.find((option) => option.value === values.type)}
                    onChange={(e) => setFieldValue('type', e.value)}
                    required = {true}
                    errorText={touched.type && errors.type ? errors.type : ''}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <InputBox
                    type="number"
                    id="value"
                    name="value"
                    label="Discount Value"
                    placeholder="Enter Discount Value"
                    value={values.value}
                    onChange={(e) => setFieldValue('value', parseFloat(e.target.value))}
                    required = {true}
                    errorText={touched.value && errors.value ? errors.value : null}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <InputBox
                    type="text"
                    id="code"
                    name="code"
                    label="Promo Code"
                    placeholder="Enter Promo Code "
                    value={values.code}
                    onChange={(e) => setFieldValue('code', e.target.value)}
                    required = {true}
                    errorText={touched.code && errors.code ? errors.code : null}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <InputBox
                    type="number"
                    id="maxUsesPerCustomer"
                    name="maxUsesPerCustomer"
                    label="Enter Maximum Number of Uses"
                    placeholder="Enter Maximum Number of Uses"
                    value={values.maxUsesPerCustomer}
                    onChange={(e) =>
                      setFieldValue(
                        'maxUsesPerCustomer',
                        e.target.value === '' ? '' : parseFloat(e.target.value)
                      )
                    }
                    required = {true}
                    errorText={touched.maxUsesPerCustomer && errors.maxUsesPerCustomer ? errors.maxUsesPerCustomer : null}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <InputBox
                    type="number"
                    id="minOrderValue"
                    name="minOrderValue"
                    label="Enter Minimum Order Value"
                    placeholder="Enter Minimum Order Value"
                    value={values.minOrderValue}
                    onChange={(e) =>
                      setFieldValue(
                        'minOrderValue',
                        e.target.value === '' ? '' : parseFloat(e.target.value)
                      )
                    }
                    required = {true}
                    errorText={touched.minOrderValue && errors.minOrderValue ? errors.minOrderValue : null}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <InputBox
                    type="number"
                    id="maxDiscount"
                    name="maxDiscount"
                    label="Enter Maximum Discount"
                    placeholder="Enter Maximum Discount"
                    value={values.maxDiscount}
                    onChange={(e) =>
                      setFieldValue(
                        'maxDiscount',
                        e.target.value === '' ? '' : parseFloat(e.target.value)
                      )
                    }
                    required = {true}
                    errorText={touched.maxDiscount && errors.maxDiscount ? errors.maxDiscount : null}
                  />
                </div>
                <SelectBox
                  id="productCategory"
                  label="Choose Applicable Product Category"
                  options={categories}
                  isMulti
                  name="productCategory"
                  value={categories?.filter((option) => values?.productCategory.includes(option.value))}
                  onChange={(e) =>
                    setFieldValue(
                      'productCategory',
                      e?.map((option) => option.value)
                    )
                  }
                  required = {true}
                  errorText={touched.productCategory && errors.productCategory ? errors.productCategory : ''}
                />

                <SelectBox
                  id="validStores"
                  label="Choose Applicable Stores"
                  options={stores} // Options filtered based on the searchQuery
                  isMulti
                  name="validStores"
                  value={stores.filter((option) => values?.validStores?.includes(option.value))}
                  onInputChange={handleInputChange} // Capture search input
                  onChange={(e) =>
                    setFieldValue(
                      "validStores",
                      e?.map((option) => option.value)
                    )
                  }
                  required = {true}
                  errorText={
                    touched.validStores && errors.validStores
                      ? errors.validStores
                      : ""
                  }
                />
                <div className="grid grid-cols-1 gap-6">
                  <SelectBox
                    name="status"
                    label="Status"
                    value={values.status}
                    onChange={(e) => { setFieldValue('status', e) }}
                    id="status"
                    options={STATUS_OPTIONS}
                    errorText={touched.status && errors.status ? errors.status : null}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <div className="grid grid-cols-2 gap-6">
                    <InputBox
                      type="datetime-local"
                      name="startDate"
                      label="Start Date"
                      placeholder="Enter Start Date"
                      value={values.startDate}
                      onChange={(e) => setFieldValue('startDate', e.target.value)}
                      errorText={touched.startDate && errors.startDate ? errors.startDate : null}
                      id="startDate"
                      required = {true}
                    />
                    <InputBox
                      type="datetime-local"
                      name="endDate"
                      label="End Date"
                      placeholder="Enter End Date"
                      value={values.endDate}
                      onChange={(e) => setFieldValue('endDate', e.target.value)}
                      errorText={touched.endDate && errors.endDate ? errors.endDate : null}
                      id="endDate"
                      required = {true}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <MultiInputBox
                    type="text"
                    id="termsandconditions"
                    name="termsandconditions"
                    label="Terms and condition of usage "
                    placeholder="Enter Terms and condition of usage"
                    value={values.termsandconditions}
                    onChange={(e) => setFieldValue('termsandconditions', e.target.value)}
                    required = {true}
                    errorText={touched.termsandconditions && errors.termsandconditions ? errors.termsandconditions : null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </OffCanvasForm>
  )
}

PromoCodeForm.propTypes = {
  id: PropTypes.string.isRequired,
  promoData: PropTypes.object,
  isEdit: PropTypes.bool,
  onComplete: PropTypes.func,
};


export default PromoCodeForm
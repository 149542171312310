import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { TAX_TYPE_OPTIONS, TAX_OPTIONS, STATUS_OPTIONS } from '@hub/constants/constants'
import { companyAction } from '@common/src/service/cloud/catalog'
import { getCountries, getPaymentOptions } from '../../../shared/src/service/firebase/api'

const validationSchema = Yup.object({
  name: Yup.object({
    en: Yup.string().required('English name is required'),
    ar: Yup.string().required('Arabic name is required'),
  }).required('Name is required'),
  email: Yup.string().email('Invalid email address').optional(),
  timezone: Yup.string().required('Time Zone is required'),
  currency: Yup.string().required('Currency is required'),
  phoneNumber: Yup.string()
    .matches(/^\d{9,15}$/, 'Phone number must contain between 9 to 15 digits')
    .optional(),
  country: Yup.string().required('Country is required'),

  // Tax type
  tin: Yup.number().when('taxType', {
    is: TAX_TYPE_OPTIONS[2].value, // If taxType matches this value
    then: (schema) => schema.notRequired(), // TIN is not required
    otherwise: (schema) => schema.required('TIN is required'), // TIN is required
  }),
  taxType: Yup.string().required('Tax Type is required'),
  posWebhook: Yup.string().required('Webhook is required'),
  paymentGatewayId: Yup.string().required('Payment gateway is required'),
  // Conditional fields
  vat: Yup.number().when('taxType', {
    is: TAX_TYPE_OPTIONS[0].value,
    then: (schema) =>
      schema.required('VAT Value is required').typeError('VAT value must be a number').min(0, 'VAT must be positive'),
    otherwise: (schema) => schema.notRequired(),
  }),

  gst: Yup.number().when('taxType', {
    is: TAX_TYPE_OPTIONS[1].value,
    then: (schema) =>
      schema.required('GST is required').typeError('GST must be a number').min(0, 'GST must be positive'),
    otherwise: (schema) => schema.notRequired(),
  }),
})

const CompanyForm = ({ id, data, isEdit, onComplete }) => {
  const toast = useToast()
  const [countryOptions, setCountryOptions] = useState([])
  const [paymentOptions, setPaymentOptions] = useState([])
  const [phoneCode, setPhoneCode] = useState('')
  const [initialValues, setInitialValues] = useState({
    name: { en: '', ar: '' },
    email: '',
    phoneNumber: '',
    phoneCode: '',
    posWebhook: '',
    paymentGatewayId: '',
    country: '',
    timezone: '',
    countryCode: '',
    currency: '',
    currencyCode: '',
    gst: '',
    vat: '',
    taxType: '',
    isInclusive: TAX_OPTIONS[0].value,
    tin: '',
  })

  useEffect(() => {
    const processCountries = (fetchCountriesList) => {
      return fetchCountriesList.map((doc) => ({
        label: doc.name,
        value: doc,
      }))
    }

    const processPaymentOptions = (getPaymentList) => {
      return getPaymentList.map((doc) => ({
        label: doc.name,
        value: doc.id,
      }))
    }

    const getDefault = (value, defaultValue) => value || defaultValue

    const initializeValues = (data) => {
      if (!data) return
      const { country = {}, taxConfig = {}, currency = {} } = data?.config || {}
      setPhoneCode(data?.phoneCode || '+91')
      setInitialValues({
        name: getDefault(data?.name, { en: '', ar: '' }),
        email: getDefault(data?.email, ''),
        phoneNumber: getDefault(data?.phoneNumber, ''),
        phoneCode: getDefault(data?.phoneCode, ''),
        posWebhook: getDefault(data?.posWebhook, ''),
        paymentGatewayId: getDefault(data?.paymentGatewayId, ''),
        country: getDefault(country?.country, ''),
        timezone: getDefault(country?.timezone, ''),
        countryCode: getDefault(country?.countryCode, ''),
        currency: getDefault(currency?.currency, ''),
        currencyCode: getDefault(currency?.currencyCode, ''),
        gst: taxConfig?.gst,
        vat: taxConfig?.vat,
        taxType: getDefault(taxConfig?.taxType, ''),
        isInclusive: taxConfig?.isInclusive ? TAX_OPTIONS[0].value : TAX_OPTIONS[1].value,
        tin: getDefault(taxConfig?.tin, ''),
      })
    }

    const fetchData = async () => {
      try {
        const [fetchCountriesList, getPaymentList] = await Promise.all([getCountries(), getPaymentOptions()])
        setCountryOptions(processCountries(fetchCountriesList))
        setPaymentOptions(processPaymentOptions(getPaymentList))
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }

    fetchData()
    initializeValues(data)
  }, [data])

  const getErrorMessage = (fieldPath, errors, touched) => {
    const error = fieldPath.split('.').reduce((acc, key) => acc?.[key], errors)
    const isTouched = fieldPath.split('.').reduce((acc, key) => acc?.[key], touched)
    return error && isTouched ? error : null
  }
  const getFormData = (values) => {
    const getNestedValue = (obj, key, defaultValue = '') => obj?.[key] || defaultValue

    return {
      name: {
        ar: getNestedValue(values.name, 'ar'),
        en: getNestedValue(values.name, 'en'),
      },
      paymentGatewayId: getNestedValue(values, 'paymentGatewayId'),
      email: getNestedValue(values, 'email'),
      posWebhook: getNestedValue(values, 'posWebhook'),
      phoneNumber: values.phoneNumber,
      phoneCode: phoneCode || '',
      config: {
        taxConfig: {
          gst: values.taxType === TAX_TYPE_OPTIONS[1].value ? values.gst : null,
          vat: values.taxType === TAX_TYPE_OPTIONS[0].value ? values.vat : null,
          taxType: values.taxType,
          isInclusive: values.taxType === 'NO_TAX' ? false : values.isInclusive === TAX_OPTIONS[0].value,
          tin: getNestedValue(values, 'tin'),
        },
        biteCoinConfig: values?.biteCoinConfig || data?.config?.biteCoinConfig,
        currency: {
          currencyCode: getNestedValue(values, 'currencyCode'),
          currency: getNestedValue(values, 'currency'),
        },
        country: {
          countryCode: getNestedValue(values, 'countryCode'),
          country: getNestedValue(values, 'country'),
          timezone: getNestedValue(values, 'timezone'),
        },
      },
      status: STATUS_OPTIONS[0].value,
      ...(data?.id && { id: parseInt(data.id) }),
    }
  }
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const formData = getFormData(values)
    try {
      await companyAction(formData, isEdit)
      const toastMessage = isEdit ? 'Company updated successfully!' : 'Company added successfully!'
      toast.success(toastMessage)
      resetForm()
      onComplete?.()
    } catch (error) {
      toast.error(isEdit ? 'Error updating Company.' : 'Error creating Company.')
      console.error('Form Submission Error:', error)
    }
  }
  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText="Cancel"
      id={id}
    >
      {({ setFieldValue, values, errors, touched }) => {
        return (
          <div className="grid grid-cols-12 gap-6">
            <div className="xl:col-span-12 col-span-12">
              <div className="box-body add-products !p-0">
                <div className="grid grid-cols-1 gap-6">
                  <div className="grid grid-cols-1 gap-6">
                    <MultiLanguageInput
                      type="input"
                      label="Company Name"
                      id="company-name-add"
                      name="name"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div className="xl:col-span-1 col-span-12">
                      <SelectBox
                        label="Country"
                        name="country"
                        value={countryOptions.find((option) => option?.value?.name === values?.country)}
                        options={countryOptions}
                        onChange={(e) => {
                          const selectedCountry = e.value
                          setPhoneCode(selectedCountry.phoneCode ? `+${selectedCountry.phoneCode}` : '')
                          setPhoneCode(`+${e.value.phoneCode}`)
                          setFieldValue('country', e.value.name)
                          setFieldValue('countryCode', e.value.iso)
                          setFieldValue('currency', e.value.currency)
                          setFieldValue('currencyCode', e.value.currencyCode)
                          setFieldValue('timezone', e.value.timeZone)
                          setFieldValue('biteCoinConfig', e.value.config.biteCoinConfig)
                        }}
                        errorText={getErrorMessage('country', errors, touched)}
                        required
                      />
                    </div>
                    <div className="xl:col-span-1 col-span-12">
                      <InputBox
                        label="Currency"
                        name="currency"
                        value={values?.currency}
                        onChange={(e) => {
                          setFieldValue('currency', e.value)
                        }}
                        disabled
                        errorText={getErrorMessage('currency', errors, touched)}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div className="xl:col-span-1 col-span-12">
                      <InputBox
                        label="TimeZone"
                        name="timezone"
                        value={values?.timezone}
                        onChange={(e) => {
                          setFieldValue('timezone', e.value)
                        }}
                        disabled
                        errorText={getErrorMessage('timezone', errors, touched)}
                        required
                      />
                    </div>
                    <div className="xl:col-span-1 col-span-12">
                      <InputBox
                        label="Email"
                        name="email"
                        type="text"
                        value={values.email}
                        maxLength={50}
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(/^\s+/, '')
                          setFieldValue('email', sanitizedValue)
                        }}
                        errorText={getErrorMessage('email', errors, touched)}
                      />
                      {/* <ErrorMessage name="defaultSalesPrice" component="div" style={{ color: 'red' }} /> */}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div className="xl:col-span-1 col-span-12">
                      <SelectBox
                        label="Payment Gateway"
                        name="paymentGatewayId"
                        options={paymentOptions} // Replace with your tax type options
                        value={paymentOptions.find((option) => option.value === values.paymentGatewayId)}
                        onChange={(e) => {
                          setFieldValue('paymentGatewayId', e.value)
                        }}
                        errorText={getErrorMessage('paymentGatewayId', errors, touched)}
                        required
                      />
                    </div>
                    <div className="xl:col-span-1 col-span-12">
                      <InputBox
                        label="POS Webhook URL"
                        name="posWebhook"
                        type="text"
                        value={values.posWebhook}
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(/^\s+/, '')
                          setFieldValue('posWebhook', sanitizedValue)
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div className="xl:col-span-1 col-span-12">
                      <div className="input-group">
                        <InputBox
                          label="Phone Number"
                          type="text"
                          name="phoneNumber"
                          prefixValue={phoneCode || null}
                          value={values.phoneNumber}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(/^\s+/, '')
                            setFieldValue('phoneNumber', sanitizedValue)
                          }}
                          errorText={getErrorMessage('phoneNumber', errors, touched)}
                          className="flex-1"
                        />
                      </div>
                    </div>
                    <div className="xl:col-span-1 col-span-12">
                      <div className="xl:col-span-1 col-span-12">
                        <SelectBox
                          label="Tax Type"
                          name="taxType"
                          options={TAX_TYPE_OPTIONS} // Replace with your tax type options
                          value={TAX_TYPE_OPTIONS.find((option) => option.value === values.taxType.toUpperCase())}
                          onChange={(e) => {
                            setFieldValue('taxType', e.value)
                            setFieldValue('gst', '')
                            setFieldValue('vat', '')
                          }}
                          errorText={getErrorMessage('taxType', errors, touched)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    {values?.taxType !== 'NO_TAX' &&
                      (values?.taxType === TAX_TYPE_OPTIONS[0].value ? (
                        <div className="xl:col-span-1 col-span-12">
                          <InputBox
                            label="VAT Value (%)"
                            name="vat"
                            type="number"
                            value={values?.vat}
                            onChange={(e) => setFieldValue('vat', parseFloat(e.target.value))}
                            min={0}
                            step="any"
                            errorText={getErrorMessage('vat', errors, touched)}
                            required
                          />
                        </div>
                      ) : (
                        <div className="xl:col-span-1 col-span-12">
                          <InputBox
                            label="GST Value(%)"
                            name="gst"
                            type="number"
                            value={values?.gst}
                            onChange={(e) => setFieldValue('gst', parseFloat(e.target.value))}
                            min={0}
                            step="any"
                            errorText={getErrorMessage('gst', errors, touched)}
                            required
                          />
                        </div>
                      ))}
                    {values?.taxType !== 'NO_TAX' && (
                      <div className="xl:col-span-1 col-span-12">
                        <div className="xl:col-span-1 col-span-12">
                          <SelectBox
                            label="Tax Option (Inclusive/Exclusive)"
                            name="isInclusive"
                            options={TAX_OPTIONS} // Replace with your tax type options
                            value={TAX_OPTIONS.find((option) => option.value === values.isInclusive)}
                            onChange={(e) => setFieldValue('isInclusive', e.value)}
                            errorText={getErrorMessage('isInclusive', errors, touched)}
                            required
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {values?.taxType !== 'NO_TAX' && (
                    <div className="grid grid-cols-2 gap-6">
                      <div className="xl:col-span-1 col-span-12">
                        <InputBox
                          label="Tax Reference Number (TIN)"
                          name="tin"
                          type="text"
                          value={values?.tin}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(/^\s+/, '')
                            setFieldValue('tin', sanitizedValue)
                          }}
                          errorText={getErrorMessage('tin', errors, touched)}
                          required
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </OffCanvasForm>
  )
}

CompanyForm.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.shape({
      en: PropTypes.string.isRequired,
      ar: PropTypes.string.isRequired,
    }).isRequired,
    email: PropTypes.string.isRequired,
    paymentGatewayId: PropTypes.string,
    posWebhook: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneCode: PropTypes.string,
    config: PropTypes.shape({
      country: PropTypes.shape({
        country: PropTypes.string.isRequired,
        timezone: PropTypes.string.isRequired,
        countryCode: PropTypes.string.isRequired,
      }).isRequired,
      currency: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        currencyCode: PropTypes.string.isRequired,
      }).isRequired,
      biteCoinConfig: PropTypes.object,
      taxConfig: PropTypes.shape({
        taxType: PropTypes.string.isRequired,
        gst: PropTypes.number, // Optional, can be `null` if not provided
        vat: PropTypes.number, // Optional, can be `null` if not provided
        isInclusive: PropTypes.bool.isRequired,
      }),
    }),
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default CompanyForm

//create a common firebase function to read data from firebase  where collection is companies with comapny id as document id and collection is like need to get as params  so basiclly catgeories,  modifiers, modifersGroup
import { collection, query, orderBy, startAfter, limit, getDocs } from 'firebase/firestore'
import { db } from '../../firebase/firebaseapi'
import { COLLECTIONS } from '@common/src/constants/constants'

export const getCompanyCollection = async (
  companyId,
  collectionName,
  limitCount = 10,
  startAfterDoc = null,
  orderByField = 'createdAt'
) => {
  try {
    const collectionRef = collection(db, `${COLLECTIONS.COMPANIES}/${companyId}/${collectionName}`)

    const companyCollectionQuery = startAfterDoc
      ? query(collectionRef, orderBy(orderByField), startAfter(startAfterDoc), limit(limitCount))
      : query(collectionRef, orderBy(orderByField), limit(limitCount))

    const querySnapshot = await getDocs(companyCollectionQuery)

    const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

    return {
      data,
      lastVisible,
    }
  } catch (error) {
    console.error('Error fetching company collection data:', error)
    return { data: [], lastVisible: null }
  }
}
export const getCompanyCollectionDoc = async (companyId, collectionName, documentId) => {
  try {
    const docRef = doc(db, `${COLLECTIONS.COMPANIES}/${String(companyId)}/${collectionName}/${String(documentId)}`)

    const docSnapshot = await getDoc(docRef)

    if (docSnapshot.exists()) {
      const data = { id: docSnapshot.id, ...docSnapshot.data() }
      return {
        data,
        lastVisible: docSnapshot, // For single doc queries, `lastVisible` is the document itself
      }
    } else {
      return { data: null, lastVisible: null }
    }
  } catch (error) {
    console.error('Error fetching company collection document:', error)
    return { data: null, lastVisible: null }
  }
}

import { combineReducers } from 'redux'
import productsReducer from './products/reducer' // Assume you create similar reducers
import themeReducer from './theme/reducer'
import authReducer from './auth/reducer'

const rootReducer = combineReducers({
  theme: themeReducer,
  product: productsReducer,
  auth: authReducer,
})

export default rootReducer


import React from 'react';
import PropTypes from 'prop-types';

const RefundModal = ({ isOpen = false, setIsOpen, action, refundAction, message, loading, reason, setReason, onClose }) => {

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setReason('');
        onClose?.();
    };

    return (
        <div id="todo-compose" className={`hs-overlay bg-primary/20 ti-modal ${isOpen ? 'open' : 'hidden'}`}>

            <div className="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out fixed inset-0 z-[10000] flex items-center justify-center">
                <div className="ti-modal-content w-full max-w-[600px] mx-auto">
                    <div className="ti-modal-header">
                        <h6 className="modal-title text-[1rem] font-semibold" id="mail-ComposeLabel">Cancel Order</h6>
                        <button type="button" className="hs-dropdown-toggle !text-[1rem] !font-semibold !text-defaulttextcolor" onClick={toggleModal}>
                            <span className="sr-only">Close</span>
                            <i className="ri-close-line"></i>
                        </button>
                    </div>

                    <div className="ti-modal-body px-4">
                        <p className='text-center font-semibold text-[1.2rem]'>{message}</p>
                        <div className="mt-2 p-6">
                            <label htmlFor="reason" className="">
                                Reason <span style={{ color: 'red' }}>*</span>
                            </label>
                            <textarea
                                type="text"
                                className="form-control w-full !rounded-md  no-resize"
                                id="reason"
                                placeholder="Enter reason"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                style={{ resize: 'none' }} />
                        </div>
                        <div className="flex justify-center space-x-4 mt-6 mb-6">
                            <button type="button" className={`ti-btn ${reason.trim() === '' ? 'bg-gray-500' : 'bg-warning'} text-white !font-medium`} disabled={reason.trim() === ''} onClick={action}>
                                Cancel Without Refund
                                {loading?.confirm && (
                                    <span className="loading">
                                        <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                                    </span>
                                )}
                            </button>
                            <button type="button" className={`ti-btn ${reason.trim() === '' ? 'bg-gray-500' : 'bg-danger'} text-white !font-medium`} disabled={reason.trim() === ''} onClick={refundAction}>
                                Cancel With Refund
                                {loading?.refund && (
                                    <span className="loading">
                                        <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

RefundModal.propTypes = {
    message: PropTypes.string,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func.isRequired,
    action: PropTypes.func,
    loading: PropTypes.object,
    refundAction: PropTypes.func,
    reason: PropTypes.string,
    setReason: PropTypes.func,
    onClose: PropTypes.func
};

export default RefundModal


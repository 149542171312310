import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getOrderStatusColor, ORDER_STATUS_LIST } from './common';
import SelectBox from '@common/src/common/selectbox/selectbox';
import EmptyData from '@common/src/common/Table/emptyData';
import InputBox from '@common/src/common/inputbox/inputbox';
import SearchBox from '@common/src/common/inputbox/searchBox';
import { ORDER_STATUS_OPTIONS, DISPUTE_ORDER_STATUS_OPTIONS } from '@hub/constants/constants';

const OrderTable = ({ orders, timeStamp = false, stores, onFilter, selectedStores, searchQuery, searchData, selectedFilterStatus, filterStartDate, filterEndDate, disputeOrder = false }) => {
    const [selectedStatus, setSelectedStatus] = useState(selectedFilterStatus || []);
    const [selectedStartDate, setSelectedStartDate] = useState(filterStartDate || '');
    const [selectedEndDate, setSelectedEndDate] = useState(filterEndDate || '');
    const [storesSelected, setStoresSelected] = useState(selectedStores || []);
    const [searchItem, setSearchItem] = useState(searchData || '');
    const [statusOptions, setStatusOptions] = useState(ORDER_STATUS_OPTIONS);

    useEffect(() => {
        if (disputeOrder) {
            setStatusOptions(DISPUTE_ORDER_STATUS_OPTIONS);
        }
    }, [disputeOrder]);


    // Function to clear all filters
    const clearFilters = () => {
        setSelectedStartDate('');
        setSelectedEndDate('');
        setSelectedStatus([]);
        setStoresSelected([]);
        onFilter('', '', '', []); // Notify parent about cleared filters
    };

    return (
        <>
            <div className="flex justify-end mb-4">
                {!timeStamp && (
                    <div className="flex mr-4 w-[30%]">
                        <SearchBox
                            classes="py-2 px-3 !ti-form-input !rounded-none !rounded-s-sm !focus:z-10"
                            width="w-full"
                            placeHolder="Search by order number/phone number"
                            value={searchItem}
                            onChange={(e) => {
                                setSearchItem(e.target.value); // Update the local state
                                if (!e.target.value.trim()) {
                                    searchQuery('')//reset
                                }
                            }}
                            onKeyDown={(e) => {
                                // Perform search when Enter key is pressed
                                if (e.key === 'Enter') {
                                    searchQuery(searchItem.trim())
                                }
                            }}
                        />
                        <button className="inline-flex flex-shrink-0 justify-center p-2 items-center rounded-e-sm border border-transparent font-semibold bg-primary text-white hover:bg-primary focus:z-10 focus:outline-none focus:ring-0 focus:ring-primary transition-all text-sm"
                            onClick={() => searchQuery(searchItem.trim())} >
                            <i className="ri-search-line"></i>
                        </button>
                    </div>
                )}
                <div className="flex">

                    <SelectBox
                        options={statusOptions}
                        placeholder="Select Status"
                        name="Status"
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e)}
                        className="mr-2 min-w-[200px]"
                    />

                    <SelectBox
                        options={stores}
                        placeholder="Select Store"
                        name="store"
                        value={storesSelected}
                        onChange={(e) => setStoresSelected(e)}
                        className="mr-2 min-w-[200px]"
                        isMulti={false}
                    />
                    {!timeStamp && (
                        <>
                            <InputBox
                                type="date"
                                name="startDate"
                                value={selectedStartDate}
                                onChange={(e) => setSelectedStartDate(e.target.value)}
                                placeholder="Enter Start Date"
                                id="startDate"
                                className="mr-2 min-w-[200px]"
                            />
                            <InputBox
                                type="date"
                                name="endDate"
                                value={selectedEndDate}
                                onChange={(e) => setSelectedEndDate(e.target.value)}
                                placeholder="Enter End Date"
                                id="endDate"
                                className="mr-2 min-w-[200px]"
                            />
                        </>
                    )}

                    <button
                        className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary mr-2"
                        onClick={() => onFilter(selectedStartDate, selectedEndDate, selectedStatus, storesSelected)}
                    >
                        Filter
                    </button>

                    <button
                        className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-danger mr-2"
                        onClick={clearFilters}
                    >
                        Clear
                    </button>
                </div>
            </div>

            {!orders?.length ?
                <EmptyData icon="shopping-cart-line" text="No orders found" />
                :
                <table className="table whitespace-nowrap min-w-full">
                    <thead>
                        <tr className="border-b border-defaultborder">
                            <th scope="col" className="text-start">Sl.No</th>
                            <th scope="col" className="text-start">Order No</th>
                            <th scope="col" className="text-start">Order By</th>
                            <th scope="col" className="text-start">Phone</th>
                            <th scope="col" className="text-start">Store</th>
                            <th scope="col" className="text-start">Order Value</th>
                            <th scope="col" className="text-start">Payment Type</th>
                            <th scope="col" className="text-start">Order Date & Time</th>
                            <th scope="col" className="text-start">Status</th>
                            <th scope="col" className="text-start">Service</th>
                            <th scope="col" className="text-start">View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((item) => (
                            <tr key={item.id} className={`${item.class1} border-b border-defaultborder`}>
                                <th scope="row">{orders.indexOf(item) + 1}</th>
                                <th scope="row">{item?.orderNumber}</th>
                                <td>{`${item?.customer?.firstName} ${item?.customer?.lastName}`}</td>
                                <td>{item?.address?.phone || item?.customer?.phoneNumber || ''}</td>
                                <td>{item?.store?.outletCode}</td>
                                <td>{item?.meta?.config?.currency?.currencyCode} {item?.total}</td>
                                <td>{item?.paymentMode}</td>
                                <td>
                                    {timeStamp && item.orderDate ? `${moment.unix(item.orderDate?.seconds).add(item.orderDate?.nanoseconds / 1000000, 'seconds').format('DD-MM-yyyy hh:mm A')}` : ``}
                                    {!timeStamp && item.orderDate ? `${moment.unix(item.orderDate / 1000).format('DD-MM-yyyy hh:mm A')}` : ``}
                                </td>
                                <td>
                                    <span className={`badge ${getOrderStatusColor(item.orderStatus)}`} >
                                        {ORDER_STATUS_LIST[item.orderStatus]}
                                    </span>
                                </td>
                                <td>{item?.service}</td>
                                <td>
                                    <Link
                                        aria-label="anchor"
                                        to={`/order-details?orderId=${item.orderId}`}
                                        className="ti-btn ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"
                                    >
                                        <i className="ri-eye-line"></i>
                                    </Link>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </>
    )
}

OrderTable.propTypes = {
    orders: PropTypes.array,
    timeStamp: PropTypes.bool,
    stores: PropTypes.array,
    onFilter: PropTypes.func,
    selectedStores: PropTypes.array,
    searchQuery: PropTypes.func,
    searchData: PropTypes.string,
    selectedFilterStatus: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    filterStartDate: PropTypes.string,
    filterEndDate: PropTypes.string,
    disputeOrder: PropTypes.bool

};

export default OrderTable;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SelectBox from '@common/src/common/selectbox/selectbox';
import Toggle from '@common/src/common/Toggle/Toggle';
import { DAY_OPTIONS } from '@hub/constants/constants';
import { useFormikContext } from 'formik';

const TimedAvailability = ({ errors, touched }) => {
const { values, setFieldValue } = useFormikContext();

useEffect(() => {
  if (values.daysAll) {
    setFieldValue('days', DAY_OPTIONS.map(option => option.value));  
  } else {  
    setFieldValue('days', []); // Clear selected days when unchecked  
  }
}, [values.daysAll, setFieldValue]);

useEffect(() => {
  if (values.dateAll) {
    setFieldValue('dateStart', null);
    setFieldValue('dateEnd', null);
  }
}, [values.dateAll, setFieldValue]);

useEffect(() => {
  if (values.timeAll) {
    setFieldValue('timeStart', '');
    setFieldValue('timeEnd', '');
  }
}, [values.timeAll, setFieldValue]);

return (
  <>
    {/* Timed Availability Toggle */}
    <div className="grid grid-cols-1 gap-6">
      <Toggle
        label="Timed"
        name="timed"
        value={values.timed}
        onChange={(e) => {
          setFieldValue('timed', e.target.checked);
        }}
      />
    </div>

    {values.timed && (
      <>
        {/* Date Range */}
        <div className="grid grid-cols-2 gap-6 mt-6">
          <div className="w-full">
            <label className="form-label" htmlFor='dateStart'>Start Date</label>
            <input
              type="checkbox"
              checked={values.dateAll}
              onChange={(e) => setFieldValue('dateAll', e.target.checked)}
              className="mt-1 ml-1"
            />
            <DatePicker
              selected={values.dateStart}
              onChange={(date) => setFieldValue("dateStart", date)}
              selectsStart
              startDate={values.dateStart}
              endDate={values.dateEnd}
              placeholderText="Start Date"
              className="form-control w-full !rounded-md"
              disabled={values.dateAll}
              dateFormat="dd/MM/yyyy"
            />
            {errors.dateStart && touched.dateStart && (
              <div style={{ color: 'red' }}>{errors.dateStart}</div>
            )}
          </div>
          <div className="w-full">
            <label className="form-label" htmlFor='dateEnd'>End Date</label>
            <DatePicker
              selected={values.dateEnd}
              onChange={(date) => setFieldValue("dateEnd", date)}
              selectsEnd
              startDate={values.dateStart}
              endDate={values.dateEnd}
              minDate={values.dateStart}
              placeholderText="End Date"
              className="form-control w-full !rounded-md"
              disabled={values.dateAll}
              dateFormat="dd/MM/yyyy"
            />
            {errors.dateEnd && touched.dateEnd && (
              <div style={{ color: 'red' }}>{errors.dateEnd}</div>
            )}
          </div>
        </div>

        {/* Days Selection */}
        <div className="grid grid-cols-1 gap-6 mt-6">
          <div className="w-full">
            <label className="form-label" htmlFor='daysAll'>Select Day(s)</label>
            <input
              type="checkbox"
              checked={values.daysAll}
              onChange={(e) => setFieldValue('daysAll', e.target.checked)}
              className="mt-1 ml-1"
            />
            <SelectBox
              name="days"
              options={DAY_OPTIONS}
              isMulti={true}
              value={DAY_OPTIONS.filter(option => values.days.includes(option.value))}
              onChange={(e) => setFieldValue("days", e.map(day => day.value))}
              placeholder="Select Days"
            />
            {errors.days && touched.days && (
              <div style={{ color: 'red' }}>{errors.days}</div>
            )}
          </div>
        </div>

        {/* Time Range */}
        <div className="grid grid-cols-2 gap-6 mt-6">
          <div className="w-full">
            <label className="form-label" htmlFor='timeStart'>Start Time</label>
            <input
              type="checkbox"
              checked={values.timeAll}
              onChange={(e) => setFieldValue('timeAll', e.target.checked)}
              className="mt-1 ml-1"
            />
            <input
              type="time"
              name="timeStart"
              value={values.timeStart}
              onChange={(e) => setFieldValue("timeStart", e.target.value)}
              className="form-control w-full !rounded-md"
              disabled={values.timeAll}
            />
            {errors.timeStart && touched.timeStart && (
              <div style={{ color: 'red' }}>{errors.timeStart}</div>
            )}
          </div>
          <div className="w-full">
            <label className="form-label" htmlFor='timeEnd'>End Time</label>
            <input
              type="time"
              name="timeEnd"
              value={values.timeEnd}
              onChange={(e) => setFieldValue("timeEnd", e.target.value)}
              className="form-control w-full !rounded-md"
              disabled={values.timeAll}
            />
            {errors.timeEnd && touched.timeEnd && (
              <div style={{ color: 'red' }}>{errors.timeEnd}</div>
            )}
          </div>
        </div>
      </>
    )}
  </>
);
};

TimedAvailability.propTypes = {
errors: PropTypes.object,
touched: PropTypes.object,
};

export default TimedAvailability;
import React from 'react'
import PropTypes from 'prop-types';

const MultiInputBox = ({
  id,
  label,
  placeholder,
  value,
  maxLength,
  onChange,
  errorText,
  required = false,
  className = '',
  labelClassName = '',
  ...rest
}) => {
  return (
    <div className={`input-box-container ${className}`}>
      {label && (
        <label htmlFor={id} className={`form-label mt-3 ${labelClassName}`}>
          {label} {required && <span style={{ color: 'red' }}>*</span>}
        </label>
      )}
      <textarea
        type="text"
        className="form-control w-full !rounded-md  no-resize"
        id={id}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        style={{ resize: 'none' }}
        {...rest}
      />
      {errorText && (
        <label htmlFor={id} className="mt-1 text-[0.75rem] opacity-[0.8] !mb-0" style={{ color: 'red' }}>
          {errorText}
        </label>
      )}
    </div>
  )
}
MultiInputBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  errorText: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
};
export default MultiInputBox
import PropTypes from 'prop-types'

const Toggle = ({ label, value, onChange, disabled }) => (
  <div className="flex items-center ">
    {label && (
      <label htmlFor={label} className="text-sm mt-3 font-medium text-gray-700 mr-3">
        {label}
      </label>
    )}
    <div className={`mt-${label ? '3' : '0'} ${!label ? 'ml-3' : ''}`}>
      <input type="checkbox" id={label} className="ti-switch" checked={value} onChange={disabled ? () => {} : onChange}/>
    </div>
  </div>
)

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
export default Toggle


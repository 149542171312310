// store/index.js
import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../modules' // Combine all reducers
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const logger = process.env.NODE_ENV === 'development' ? createLogger() : []

const store = configureStore({
  reducer: rootReducer,

  // middleware: [sagaMiddleware],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable immutable check for performance
      serializableCheck: false, // Disable serializable check for performance
    }).concat(sagaMiddleware, logger),
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga)

export default store

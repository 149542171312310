import * as Sentry from "@sentry/react";
import Dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { ROLE } from '../constants/constants'


Dayjs.extend(utc)
Dayjs.extend(timezone)

export function convertTime(time) {
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}
export const getInitials = (firstName = '', lastName = '') => {
  const firstInitial = firstName.slice(0, 1).toUpperCase()
  const lastInitial = lastName.slice(0, 1).toUpperCase()
  return `${firstInitial}${lastInitial}`.trim()
}

/**
 * Normalizes the value for the SelectBox component.
 * @param {string|array} value - The value(s) to normalize.
 * @returns {object|array|null} - The normalized value object(s) or null if not found.
 */
export const normalizeSelectBoxValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((val) => ({ value: val, label: val }))
  }
  if (typeof value === 'string') {
    return { value, label: value }
  }
  return null
}

export const timeStamptoDate = (time_stamp_date, timezone, format) => {
  return time_stamp_date ? Dayjs.tz(time_stamp_date.toDate(), timezone).format(format || 'YYYY-MM-DDTHH:mm') : ''
}
export const isHub = () => {
  return import.meta.env.VITE_PORTAL === ROLE.ROLE_TYPE_HUB
}

// Helper function to normalize a date to midnight
export const normalizeDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export const transformToValueLabel = (values, options) => {  
  return options.filter((option) => values.includes(option.value));  
};

export const normalizeDateFormat = (date) => {  
  if (!date) return null;  
  const d = new Date(date);  
  const year = d.getFullYear();  
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based  
  const day = String(d.getDate()).padStart(2, '0');  
  return `${year}-${month}-${day}`;  
};
export const reportLog = (errorMessage, errorLevel) => {
  console.log(errorMessage, "errorMessage")
  return Sentry.captureMessage(errorMessage, errorLevel);
};
export const reportError = (message, error) => {
  return Sentry.captureException(new Error(`${message} Error: ${error}`));
}
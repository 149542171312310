import { useState } from 'react'

const useFloatingCanvas = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [activeComponent, setActiveComponent] = useState(null)
  const [offCanvasTitle, setOffCanvasTitle] = useState('')

  const openOffCanvas = (component, title) => {
    setActiveComponent(component)
    setOffCanvasTitle(title)
    setIsVisible(true)
  }

  const closeOffCanvas = () => {
    setIsVisible(false)
    setActiveComponent(null)
    setOffCanvasTitle('')
  }

  return {
    isVisible,
    activeComponent,
    offCanvasTitle,
    openOffCanvas,
    closeOffCanvas,
  }
}

export default useFloatingCanvas

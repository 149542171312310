import { takeLatest } from 'redux-saga/effects'

export const ThemeChanger = (value) => ({
  type: 'THEME_CHANGER', // Changed to uppercase
  payload: value,
})

function* themeChangerSaga(action) {
  // Perform async tasks or additional logic if needed (e.g., fetching data)
  // No need to dispatch the same action again
}

function* watchThemeChanger() {
  yield takeLatest('THEME_CHANGER', themeChangerSaga)
}

export default watchThemeChanger

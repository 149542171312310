import React from 'react';
import PropTypes from 'prop-types';

const PreviewImage = ({ src, isRemovable = false, onRemove}) => {

    return (
        <div className="relative inline-block flex justify-center bg-gray-100 max-h-[300px]">
            {src ? (
                <img
                    src={src}
                    alt=""
                    className="block w-full h-full object-contain"
                    onLoad={(e) => {
                        e.target.parentElement.classList.remove('loading')
                    }}
                />
            ) : (
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
                </div>
            )}
            {isRemovable &&
                <button
                    className="absolute top-1 right-1 text-white bg-danger hover:bg-red/80  py-0.5 px-2"
                    onClick={onRemove}
                >
                    &times;
                </button>
            }
        </div>
    );
};
PreviewImage.propTypes = {
    src: PropTypes.string.isRequired,
    isRemovable: PropTypes.bool,
    onRemove: PropTypes.func,
}
export default PreviewImage;

import React from 'react'
import PropTypes from 'prop-types'

const InputBox = ({
  id,
  label,
  placeholder,
  value,
  maxLength,
  onChange,
  errorText,
  className = '',
  labelClassName = '',
  required = false,
  inputClassName = '',
  prefixValue="",
  ...rest
}) => {
  return (
    <div className={`input-box-container ${className}`}>
      {label && (
        <label htmlFor={id} className={`form-label mt-3 ${labelClassName}`}>
          {label} {required && <span style={{ color: 'red' }}>*</span>}
        </label>
      )}
      <div className="flex rounded-sm">
        {prefixValue && (
        <span className="px-4 inline-flex items-center min-w-fit rounded-s-sm border-e-0 border-gray-200 bg-light text-sm text-gray-500 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50">{prefixValue}</span>
        )}
        <input
          type="text"
          className={`form-control w-full !rounded-md py-2 px-3 ti-form-input rounded-none rounded-e-sm focus:z-10 !border-s-0 ${inputClassName}`}
          id={id}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          onChange={onChange}
          {...rest}
        />
      </div>
      {errorText && (
        <label htmlFor={id} className="mt-1 text-[0.75rem] opacity-[0.8] !mb-0" style={{ color: 'red' }}>
          {errorText}
        </label>
      )}
    </div>
  )
}

InputBox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errorText: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string, // Add inputClassName to propTypes
  prefixValue: PropTypes.string
}

export default InputBox

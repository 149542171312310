// roles.js
import PropTypes from 'prop-types'
import { db } from '../../firebase/firebaseapi'
import { collection, getDocs } from 'firebase/firestore'
import { COLLECTIONS } from '../../constants/constants'

export const getRole = async (uid) => {
  try {
    const rolesSnapshot = await getDocs(collection(db, COLLECTIONS.USERS, uid, COLLECTIONS.ROLES))
    const rolesData = rolesSnapshot.docs.map((roleDoc) => ({ id: roleDoc.id, ...roleDoc.data() }))
    return rolesData
  } catch (error) {
    console.error('Error fetching user data or roles:', error)
  }
}

getRole.propTypes = {
  uid: PropTypes.string.isRequired,
}

// roles.js
import { getDocs, collection } from 'firebase/firestore'
import { db } from '../../../../src/firebase/firebaseapi'
import { COLLECTIONS } from '../../constants/constants'

export const getCompany = async () => {
  try {
    const companiesCollection = collection(db, COLLECTIONS.COMPANIES)
    const querySnapshot = await getDocs(companiesCollection)
    const companies = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    return companies
  } catch (error) {
    console.error('Error fetching company data:', error)
  }
}

export const getCatagories = async (companyId) => {
  try {
    const categoriesCollection = collection(db, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CATEGORIES}`);
    const querySnapshot = await getDocs(categoriesCollection);
    const categories = querySnapshot.docs
      .filter((doc) => doc.data().status === 'ACTIVE')
      .map((doc) => ({ id: doc.id, ...doc.data() }));
    return categories;
  } catch (error) {
    console.error('Error fetching categories data:', error);
  }
}

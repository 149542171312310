import loaderImg from './loader.svg'

const LoaderSm = () => {
  return (

    <div className='flex items-center justify-center'>
      <img src={loaderImg} alt=''/>
    </div>
  )
}

export default LoaderSm
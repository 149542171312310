import {  useMemo, useRef, useState, useCallback } from 'react'
import PageHeader from '@common/src/pageheader/pageheader'
import TableList from '@common/src/common/Table/TableList'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import CompanyForm from './companyForm'

const getColumns = (onEditClick, onDeleteClick) => [
  { title: 'Name', dataIndex: 'name.en', key: 'name' },
  { title: 'Email', dataIndex: 'email', key: 'email' },
  {
    title: 'Actions',
    key: 'actions',
    render: (_, record) => (
      <div className="space-x-1 rtl:space-x-reverse">
        <button onClick={() => onEditClick(record)} className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm">
          <i className="ri-pencil-line"></i>
        </button>
        <button onClick={() => onDeleteClick(record)} className="ti-btn ti-btn-danger ti-btn-icon ti-btn-sm">
          <i className="ri-delete-bin-5-line"></i>
        </button>
      </div>
    ),
  },
]

const Company = () => {
  const tableRef = useRef(null)
  const [companyData, setCompanyData] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()

  const handleEditClick = useCallback((data) => {
    setCompanyData(data)
    setIsEdit(true)
    openOffCanvas('addCompany', 'Edit Company')
  }, [openOffCanvas])

  const handleDeleteClick = useCallback((data) => {
    // Add delete logic here
  }, [])

  const columns = useMemo(() => getColumns(handleEditClick, handleDeleteClick), [handleEditClick, handleDeleteClick])

  const handleComplete = () => {
    if (tableRef.current) {
      tableRef.current.refreshCurrentPage()
      closeOffCanvas()
    }
  }

  const handleAddClick = () => {
    setCompanyData(null)
    setIsEdit(false)
    openOffCanvas('addCompany', 'Add Company')
  }

  return (
    <>
      <PageHeader currentpage="Companies" activepage="Company Management" mainpage="company" />
      <TableList
        name="Companies"
        columns={columns}
        dbRef={dbRef}
        pagination={{ pageSize: 20 }}
        tools={{ btnName: 'Add Company', btnIcon: 'ri-add-line', btnClick: handleAddClick }}
        ref={tableRef}
      />
      <FloatingCanvas onClose={closeOffCanvas} isVisible={isVisible} title={offCanvasTitle}>
        {activeComponent === 'addCompany' && (
          <CompanyForm data={companyData} isEdit={isEdit} onComplete={handleComplete} />
        )}
      </FloatingCanvas>
    </>
  )
}

export default Company

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toggle from '@common/src/common/Toggle/Toggle';
import InputBox from '@common/src/common/inputbox/inputbox';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_STORE_PRODUCTS, GET_PRODUCT_DETAILS } from '@common/src/lib/graphql/query/product';
import { useToast } from '@common/src/common/Toast/ToastProvider';
import { useSelector } from 'react-redux';
import { assignProduct } from '@common/src/service/cloud/catalog';

const LocationTable = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [enabledData, setEnabledData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [productDetails, setProductDetails] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [loading, setLoading] = useState(false);
  const { selectedCompany } = useSelector((state) => state.auth);
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get('productId');

  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const { data, refetch } = useQuery(GET_COMPANY_STORE_PRODUCTS, {
    variables: {
      companyId: selectedCompany?.value,
      productId: parseInt(productId),
      search: searchTerm,
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });

  const { data: productData } = useQuery(GET_PRODUCT_DETAILS, {
    variables: { id: parseInt(productId) }, // Ensure id is an integer
    skip: !productId, // Skip the query if no id is present
  });

  useEffect(() => {
    if (productData) {
      setProductDetails(productData.adminProductDetailsQuery);
    }
  }, [productData]);

  useEffect(() => {
    if (data) {
      setStoreData(data.companyStoreProductsQuery.rows);
      // Initialize selectedIds based on isAvailable
      const initialSelectedIds = data.companyStoreProductsQuery.rows
        .filter((store) => store?.products?.isAvailable)
        .map((store) => store.id);
      setSelectedIds(initialSelectedIds);

      // Initialize input values with existing prices
      const initialInputValues = {};
      data.companyStoreProductsQuery.rows.forEach((store) => {
        initialInputValues[store.id] = {
          DELIVERY: store?.products?.prices?.DELIVERY || defaultSalesPrice,
          TAKEAWAY: store?.products?.prices?.TAKEAWAY || defaultSalesPrice,
          GLOBAL_DELIVERY: store?.products?.prices?.GLOBAL_DELIVERY || defaultSalesPrice,
          GLOBAL_TAKEAWAY: store?.products?.prices?.GLOBAL_TAKEAWAY || defaultSalesPrice,
        };
      });
      setInputValues(initialInputValues);
    }
  }, [data, productDetails]);

  useEffect(() => {
    refetch({
      variables: {
        companyId: selectedCompany?.value,
        productId: parseInt(productId),
        search: searchTerm,
        limit: pagination.limit,
        offset: pagination.offset,
      },
    });
  }, [pagination.offset, searchTerm]);

  const handleNext = () => {
    if (data.companyStoreProductsQuery.rows.length < pagination.limit ) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      offset: prevPagination.offset + prevPagination.limit,
      currentPage: prevPagination.currentPage + 1,
    }));
  };

  const handlePrevious = () => {
    if (pagination.offset <= 0) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      offset: prevPagination.offset - prevPagination.limit,
      currentPage: prevPagination.currentPage - 1,
    }));
  };

  const handleInputChange = (storeId, priceType, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [storeId]: {
        ...prevValues[storeId],
        [priceType]: value,
      },
    }));

    setEnabledData((prev) => {
      const existingStore = prev.find((store) => store.storeId === storeId);
      if (existingStore) {
        return prev.map((store) =>
          store.storeId === storeId
            ? { ...store, prices: { ...store?.prices, [priceType]: value } }
            : store
        );
      }
      return [...prev, { storeId, prices: { [priceType]: value } }];
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    // Create a map of enabledData for quick lookup
    const enabledDataMap = enabledData.reduce((acc, store) => {
      acc[store.storeId] = store;
      return acc;
    }, {});

    // Combine existing available stores with enabledData, filtering out those with toggle off
    const combinedData = storeData
      .filter((store) => selectedIds.includes(store.id)) // Only include stores with toggle on
      .map((store) => {
        const existingPrices = store?.products?.prices || {};
        const newPrices = enabledDataMap[store.id]?.prices || {};
        return {
          storeId: store.id,
          prices: {
            DELIVERY: newPrices.DELIVERY !== undefined ? parseFloat(newPrices.DELIVERY) : parseFloat(existingPrices.DELIVERY) || defaultSalesPrice,
            TAKEAWAY: newPrices.TAKEAWAY !== undefined ? parseFloat(newPrices.TAKEAWAY) : parseFloat(existingPrices.TAKEAWAY) || defaultSalesPrice,
            GLOBAL_DELIVERY: newPrices.GLOBAL_DELIVERY !== undefined ? parseFloat(newPrices.GLOBAL_DELIVERY) : parseFloat(existingPrices.GLOBAL_DELIVERY) || defaultSalesPrice,
            GLOBAL_TAKEAWAY: newPrices.GLOBAL_TAKEAWAY !== undefined ? parseFloat(newPrices.GLOBAL_TAKEAWAY) : parseFloat(existingPrices.GLOBAL_TAKEAWAY) || defaultSalesPrice,
          },
        };
      });

    const submittedData = {
      productId: parseInt(productId),
      stores: combinedData,
    };

    try {
      const response = await assignProduct(submittedData);
      if (response) {
        toast.success("Product assigned successfully");
        const path = `${import.meta.env.BASE_URL}menu-management`;
        navigate(path);
      }
    } catch {
      toast.error("Failed to assign product");
    } finally {
      setLoading(false); //End loading
    }
  };

  // Toggle individual item
  const handleToggleChange = (id, value) => {
    setSelectedIds((prevIds) =>
      value ? [...prevIds, id] : prevIds.filter((itemId) => itemId !== id)
    );
  };

  // Toggle all items
  const handleToggleAll = () => {
    const allIds = storeData.map((item) => item.id);
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.length === allIds.length ? [] : allIds
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      offset: 0, // Reset offset when a new search is initiated
      currentPage: 1,
    }));
  };

  const services = productDetails?.services || [];
  const defaultSalesPrice = productDetails?.defaultSalesPrice || 0;
  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="box-header">
              <div className="box-title">{productDetails?.name?.en} - Price and Availability in stores</div>
              <input
                type="text"
                placeholder="Search stores..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
              />
            </div>
            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">
                        Name
                      </th>
                      <th scope="col" className="text-start">
                        Availability{' '}
                        <button onClick={handleToggleAll} type="button">
                          {selectedIds.length === storeData.length ? "Deselect All" : "Select All"}
                        </button>
                      </th>
                      {services.includes("DELIVERY") && (
                        <th scope="col" className="text-start">
                          Delivery Price
                        </th>
                      )}
                      {services.includes("TAKEAWAY") && (
                        <th scope="col" className="text-start">
                          Takeaway Price
                        </th>
                      )}
                      {services.includes("GLOBAL_DELIVERY") && (
                        <th scope="col" className="text-start">
                          Global Delivery Price
                        </th>
                      )}
                      {services.includes("GLOBAL_TAKEAWAY") && (
                        <th scope="col" className="text-start">
                          Global Takeaway Price
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {storeData.map((item) => (
                      <tr className="product-list" key={item.id}>
                        <td>
                          <div className="flex items-center">
                            <div className="me-2">
                              <span className="avatar avatar-md avatar-rounded">
                                <img src={item.photo} alt={item.name.en} />
                              </span>
                            </div>
                            <div className="font-semibold">{item.name.en}</div>
                          </div>
                        </td>
                        <td>
                          <Toggle
                            label="Availability"
                            value={selectedIds.includes(item.id)}
                            onChange={(e) => handleToggleChange(item.id, e.target.checked)}
                          />
                        </td>
                        {services.includes("DELIVERY") && (
                          <td>
                            <InputBox
                              placeholder="Delivery Price"
                              name="deliveryPrice"
                              value={inputValues[item.id]?.DELIVERY ?? defaultSalesPrice}
                              onChange={(e) => handleInputChange(item.id, "DELIVERY", e.target.value)}
                            />
                          </td>
                        )}
                        {services.includes("TAKEAWAY") && (
                          <td>
                            <InputBox
                              placeholder="Takeaway Price"
                              name="takeawayPrice"
                              value={inputValues[item.id]?.TAKEAWAY ?? defaultSalesPrice}
                              onChange={(e) => handleInputChange(item.id, "TAKEAWAY", e.target.value)}
                            />
                          </td>
                        )}
                        {services.includes("GLOBAL_DELIVERY") && (
                          <td>
                            <InputBox
                              placeholder="Global Delivery Price"
                              name="globalDeliveryPrice"
                              value={inputValues[item.id]?.GLOBAL_DELIVERY ?? defaultSalesPrice}
                              onChange={(e) => handleInputChange(item.id, "GLOBAL_DELIVERY", e.target.value)}
                            />
                          </td>
                        )}
                        {services.includes("GLOBAL_TAKEAWAY") && (
                          <td>
                            <InputBox
                              placeholder="Global Takeaway Price"
                              name="globalTakeawayPrice"
                              value={inputValues[item.id]?.GLOBAL_TAKEAWAY ?? defaultSalesPrice}
                              onChange={(e) => handleInputChange(item.id, "GLOBAL_TAKEAWAY", e.target.value)}
                            />
                          </td>
                        )}
                      </tr>
                    ))}

                    {storeData.length === 0 && (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No stores found
                        </td>
                      </tr>
                    )}  
                  </tbody>
                </table>
              </div>
              <div className="sm:flex items-center justify-between flex-wrap">
                <nav aria-label="Page navigation">
                  <ul className="ti-pagination mb-0 flex flex-row rounded-sm text-[1rem] !ps-0">
                    <li className="page-item disabled">
                      <button className="page-link !py-[0.375rem] !px-[0.75rem]" type='button' onClick={handlePrevious}>
                        Previous
                      </button>
                    </li>
                    <li className="page-item active">
                      <button className="page-link !py-[0.375rem] !px-[0.75rem]" to="#">
                        {pagination.currentPage}
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link !py-[0.375rem] !px-[0.75rem]" type='button' onClick={handleNext}>
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* Submit Button */}
              <div className="flex justify-end">
              <button type="submit" className="ti-btn ti-btn-primary !font-medium m-1" disabled={loading}>
                {loading ? (
                  <>
                    <span className="me-2">Loading</span>
                    <span className="loading">
                      <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                    </span>
                  </>
                ) : (
                  "Submit"
                )}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LocationTable;
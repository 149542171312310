import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { collection } from 'firebase/firestore'
import PageHeader from '@common/src/pageheader/pageheader'
import TableList from '@common/src/common/Table/TableList'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import { COLLECTIONS, COMPANY_ID } from '@common/src/constants/constants'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { modifierAction } from '@common/src/service/cloud/catalog'

import { CATALOG } from '../../../constants/constants'
import { db } from '../../../firebase/firebaseapi'
import ModifierForm from './modifierForm'
import Toggle from '@common/src/common/Toggle/Toggle'

const getColumns = ({onEditClick, onStatusToggleClick, modifierDisabled}) => [
  { title: 'Name', dataIndex: 'modItemName.en', key: 'name' },
  { title: 'POS Id', dataIndex: 'posId', key: 'posId' },
  { title: 'Sort Order', dataIndex: 'sortOrder', key: 'sortOrder' },
  { title: 'Status', dataIndex: 'status', key: 'status', render: (text) => <>{text}</> },
  {
    title: 'Actions',
    key: 'actions',
    render: (_, record) => (
      <div className="space-x-1 rtl:space-x-reverse flex">
        <button onClick={() => onEditClick(record)} className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm">
          <i className="ri-pencil-line"></i>
        </button>
        <Toggle
          label=""
          value={record.status === 'ACTIVE'}
          onChange={() => onStatusToggleClick(record.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE', record)}
          disabled={modifierDisabled}
        />
      </div>
    ),
  },
]

const Modifier = () => {
  const tableRef = useRef(null)
  const { selectedCompany } = useSelector((state) => state.auth)
  const [dbRef, setDbRef] = useState(null)
  const [modifierData, setModifierData] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
  const toast = useToast()
  const [modifierDisabled, setModifierDisabled] = useState(false);

  useEffect(() => {
    if (selectedCompany) {
      setDbRef(
        collection(
          db,
          `${COLLECTIONS.COMPANIES}/${selectedCompany?.value ? selectedCompany?.value : COMPANY_ID}/${CATALOG.MODIFIER_COLLECTION}`
        )
      )
    }
  }, [selectedCompany])

  const onStatusToggleClick = async (status, record) => {
    setModifierDisabled(true)
    const formData = {
      ...record,
      status: status,
      ...(record?.id && { id: parseInt(record?.id) }),
    }
    try {

      await modifierAction(formData, true)
      toast.success(`Modifier status updated  to ${status.toLowerCase()}`)
      tableRef.current.refreshCurrentPage()
    } catch (error) {
      toast.error('Error updating Modifier')
    }finally{
      setModifierDisabled(false)
    }
  }

  const onEditClick = useCallback(
    (data) => {
      console.log(data)
      setModifierData(data)
      setIsEdit(true)
      openOffCanvas('addModifier', 'Edit Modifier')
    },
    [openOffCanvas]
  )

  const columns = useMemo(() => getColumns({ onEditClick, onStatusToggleClick, modifierDisabled }), [onEditClick, onStatusToggleClick, modifierDisabled])

  const handleComplete = () => {
    if (tableRef.current) {
      tableRef.current.refreshCurrentPage()
      closeOffCanvas()
    }
  }

  const handleAddClick = () => {
    setModifierData(null)
    setIsEdit(false)
    openOffCanvas('addModifier', 'Add Modifier')
  }

  return (
    <>
      <PageHeader currentpage="Modifiers" activepage="Catalog Management" mainpage="catalog" />
      <TableList
        name="Modifiers"
        columns={columns}
        dbRef={dbRef}
        pagination={{ pageSize: 20 }}
        tools={{ btnName: 'Add Modifier', btnIcon: 'ri-add-line', btnClick: handleAddClick }}
        ref={tableRef}
      />
      <FloatingCanvas onClose={closeOffCanvas} isVisible={isVisible} title={offCanvasTitle}>
        {activeComponent === 'addModifier' && (
          <ModifierForm
            data={modifierData}
            isEdit={isEdit}
            onComplete={handleComplete}
            companyId={selectedCompany?.value ? selectedCompany?.value : COMPANY_ID}
          />
        )}
      </FloatingCanvas>
    </>
  )
}

export default Modifier

import React from 'react';
import PropTypes from 'prop-types';

const EmptyData = ({ icon, text }) => (
    <div className="mt-6 flex items-center justify-center text-gray h-[calc(100vh-150px)] flex-col">
        <i className={`ri-${icon}`} style={{ fontSize: '6rem' }}></i>
        <span className="mt-2 text-2xl">{text}</span>
    </div>
);

EmptyData.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
};

export default EmptyData;

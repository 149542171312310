import { useEffect, useMemo, useRef, useState } from 'react'
import { collection } from 'firebase/firestore'
import { useSelector } from 'react-redux'

import TableList from '@common/src/common/Table/TableList'
import PageHeader from '@common/src/pageheader/pageheader'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas.jsx'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas.js'
import Toggle from '@common/src/common/Toggle/Toggle'
import { useToast } from '@common/src/common/Toast/ToastProvider'

import { CATALOG } from '../../../constants/constants'
import { db } from '../../../firebase/firebaseapi'
import CategoryForm from './categoryForm'
import { COLLECTIONS } from '@common/src/constants/constants'
import { categoryAction } from '@common/src/service/cloud/catalog'

const getColumns = ({ onEditClick, onStatusToggleClick, categoryDisabled }) => [
  {
    title: 'Name',
    dataIndex: 'name.en',
    key: 'name',
  },
  
  {
    title: 'Description',
    dataIndex: 'description.en',
    key: 'desc',
    render: (text) => (
      <div className="whitespace-normal">
        {text}
      </div>
    ),
  },
  {
    title: 'POS Id',
    dataIndex: 'posId',
    key: 'posId',
  },
  {
    title: 'Sort Order',
    dataIndex: 'sortOrder',
    key: 'sortOrder',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => <>{text}</>,
  },
  {
    title: 'Action',
    key: 'action',
    className: 'text-start',
    render: (text, record) => (
      <div className="space-x-1 rtl:space-x-reverse flex">
        <button
          onClick={() => onEditClick(record)}
          aria-label="button"
          type="button"
          className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
        >
          <i className="ri-pencil-line"></i>
        </button>
        <Toggle
          label=""
          value={record.status === 'ACTIVE'}
          onChange={() => onStatusToggleClick(record)}
          disabled={categoryDisabled}
        />
      </div>
    ),
  },
]

const pagination = { pageSize: 20 }

const Category = () => {
  const tableRef = useRef(null)
  const { selectedCompany } = useSelector((state) => state.auth)
  const [dbRef, setDbRef] = useState(null)
  const [data, setData] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [categoryDisabled, setCategoryDisabled] = useState(false);
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
  const toast = useToast()

  useEffect(() => {
    if (selectedCompany) {
      setDbRef(collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${CATALOG.CATEGORY_COLLECTION}`))
    }
  }, [selectedCompany])

  // Function to handle status update
  const handleCategoryUpdate = async (status, record) => {
    setCategoryDisabled(true)
    const formData = { status, ...(record?.id && { id: parseInt(record?.id) }), companyId: parseInt(record.companyId) }
    try {
      await categoryAction(formData, true)
      toast.success(`Category status updated to ${status.toLowerCase()}`)
      tableRef.current.refreshCurrentPage()
    } catch (error) {
      toast.error('Error updating Category')
    }finally{
      setCategoryDisabled(false)
    }
  }

  const onEditClick = (data) => {
    setData(data)
    setIsEdit(true)
    openOffCanvas('addCategory', 'Edit Category')
  }

  const onStatusToggleClick = (record) => {
    const newStatus = record.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    handleCategoryUpdate(newStatus, record)
  }

  const columns = useMemo(() => getColumns({ onEditClick, onStatusToggleClick, categoryDisabled }), [onEditClick, onStatusToggleClick, categoryDisabled])
  const onComplete = () => {
    if (tableRef.current) {
      tableRef.current.refreshCurrentPage()
      closeOffCanvas()
    }
  }

  const onClose = () => {
    closeOffCanvas(false)
  }

  const onAddClick = () => {
    setData(null)
    setIsEdit(false)
    openOffCanvas('addCategory', 'Add Category')
  }

  return (
    <>
      <PageHeader currentpage="Categories" activepage="Catalog Management" mainpage="Category" />

      <TableList
        name="Categories"
        columns={columns}
        dbRef={dbRef}
        pagination={pagination}
        tools={{
          btnName: 'Add Category',
          btnClick: onAddClick,
        }}
        ref={tableRef}
      />
      <FloatingCanvas onClose={onClose} isVisible={isVisible} close={closeOffCanvas} title={offCanvasTitle}>
        {activeComponent === 'addCategory' && (
          <CategoryForm data={data} isEdit={isEdit} onComplete={onComplete} companyId={selectedCompany.value} />
        )}
      </FloatingCanvas>
    </>
  )
}

export default Category

import { getDocs, collection, query, doc, getDoc } from 'firebase/firestore'
import { db } from '../../../../src/firebase/firebaseapi'
import { COLLECTIONS } from '../../constants/constants'
import { CATALOG } from '@hub/constants/constants';

export const getCategories = async (selectedCompany) => {
  try {
    let dataQuery = query(collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${CATALOG.CATEGORY_COLLECTION}`))
    const productsSnapshot = await getDocs(dataQuery)
    const products = productsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    
    return products
  } catch (error) {
    console.error('Error fetching Category data:', error);
  }
};

export const getCategoryById = async (selectedCompany, id) => {
  try {
    const docRef = doc(db, `${COLLECTIONS.COMPANIES}/${selectedCompany}/${CATALOG.CATEGORY_COLLECTION}/${id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() }
    }
    return null;
  } catch (error) {
    console.error('Error fetching category data:', error);
    return null;
  }
};

import { gql } from '@apollo/client';

export const GET_ALL_COMPANIES = gql`
  query companyListQuery($search: String, $limit: Int, $offset: Int) {
    companyQuery(search: $search, limit: $limit, offset: $offset) {
      limit
      offset
      totalPages
       rows{
        id
        name{
          en
          ar
        }
        posWebhook
        phoneCode
        phoneNumber
        email
        paymentGatewayId
        config{
          currency{
            currencyCode
            currency
          }
          taxConfig{
            taxType
            vat
            gst
            isInclusive
            tin
          }
          country{
            countryCode
            country
            timezone
          }
          biteCoinConfig{
          minCoin
          coinValue
          conversion
          }
        }
      }
    }
  }
`;

import { db } from '../../firebase/firebaseapi'
import { collection, doc, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { COLLECTIONS, CATALOG } from '../../constants/constants'

export const addNotifications = async (data, companyId) => {
  try {
    const notificationRef = collection(db, COLLECTIONS.COMPANIES, companyId.toString(), CATALOG.NOTIFICATION)
    let imageUrl = ''

    // Check if there is an image file to upload
    if (data.image) {
      const storage = getStorage()
      const storageRef = ref(storage, `${COLLECTIONS.NOTIFICATIONS}/${data.image.name}`)

      // Upload the file
      await uploadBytes(storageRef, data.image)
      // Get the download URL
      imageUrl = await getDownloadURL(storageRef)
    }

    const formData = {
      name: {
        en: data.name?.en || '',
        ar: data.name?.ar || '',
      },
      description: {
        en: data.description?.en || '',
        ar: data.description?.ar || '',
      },
      image: imageUrl, // Store the image URL instead of the File object
      status: data.status,
      notificationType: data?.notificationType || '',
      link: data?.link || '',
      createdAt: serverTimestamp(),
    }

    await addDoc(notificationRef, formData)
  } catch (error) {
    console.error('Error adding notification: ', error)
  }
}

export const updateNotificationData = async (id, data, companyId) => {
  try {
    const notificationRef = doc(db, COLLECTIONS.COMPANIES, companyId.toString(), CATALOG.NOTIFICATION, id)
    let imageUrl = data?.image;

    // Check if there's a new image file to upload
    if (data.image instanceof File) {
      const storage = getStorage()
      const storageRef = ref(storage, `${COLLECTIONS.NOTIFICATIONS}/${data.image.name}`)

      await uploadBytes(storageRef, data.image)

      imageUrl = await getDownloadURL(storageRef)
    }

    const updatedData = {
      name: {
        en: data.name?.en || '',
        ar: data.name?.ar || '',
      },
      description: {
        en: data.description?.en || '',
        ar: data.description?.ar || '',
      },
      image: imageUrl,
      status: data.status,
      notificationType: data?.notificationType || '',
      link: data?.link || '',
      updatedAt: serverTimestamp(),
    }

    await updateDoc(notificationRef, updatedData)
  } catch (error) {
    console.error('Error updating notification: ', error)
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

const Footer = ({ id, okText, closeText, goodies = {}, onSubmit, loading }) => {
  const { isSubmitting } = goodies || {}

  return (
    <div className="flex justify-end">
      <button
        type="button"
        onClick={() => HSOverlay.close(`#${id}`)}
        disabled={isSubmitting || loading}
        className="ti-btn bg-danger text-white !font-medium m-1"
      >
        {closeText}
      </button>

      <button type="submit" disabled={isSubmitting || loading}  className="ti-btn bg-primary ti-btn-loader text-white m-1">
        {isSubmitting || loading ? <span className="me-2">Loading</span> : okText}
        {(isSubmitting || loading) && (
          <span className="loading">
            <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
          </span>
        )}
      </button>
    </div>
  )
}
const OffCanvasForm = ({
  id,
  children,
  validationSchema,
  initialValues,
  onSubmit,
  okText,
  closeText,
  autoClose = true,
  loading = false,
}) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      okText={okText}
      closeText={closeText}
      onSubmit={async (values, actions) => {
        try {
          await onSubmit(values, actions)
          autoClose && HSOverlay.close(`#${id}`)
        } catch (error) {
          console.error('Error submitting form:', error)
        }
      }}
    >
      {(goodies) => {
        // console.log(goodies.errors, goodies.values, goodies.touched)
        return (
          <Form>
            <div className="ti-offcanvas-body !h-[90%] m-5" style={{ margin: '20px' }}>
              <div className="col-span-12 m-5">{children(goodies)}</div>
            </div>
            <div className="ti-offcanvas-footer sm:flex justify-between">
              <Footer
                id={id}
                goodies={goodies}
                okText={okText}
                onSubmit={onSubmit}
                closeText={closeText}
                loading={loading}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

// Add prop types for validation
OffCanvasForm.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  validationSchema: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  okText: PropTypes.string,
  closeText: PropTypes.string,
  autoClose: PropTypes.bool,
  loading: PropTypes.bool,
}
Footer.propTypes = {
  id: PropTypes.string.isRequired,
  goodies: PropTypes.object.isRequired,
  okText: PropTypes.string,
  closeText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired, 
  loading: PropTypes.bool,
}

export default OffCanvasForm

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ORDER_DETAILS } from '@common/src/lib/graphql/query/orders';
import LoaderSm from '@common/src/common/loader/loader-sm';
import EmptyData from '@common/src/common/Table/emptyData';
import { useLocation } from 'react-router-dom';
import { getOrderStatusColor, ORDER_PAYMENT_STATUS, ORDER_STATUS_LIST, SERVICES, ORDER_STATUS_DELIVERY_OPTIONS, PAYMENT_MODES, ORDER_STATUS_TAKE_AWAY_OPTIONS, ORDER_PAYMENT_STATUS_COD } from './tabContents/common';
import SelectBox from '@common/src/common/selectbox/selectbox';
import moment from 'moment';
import Loader from '@common/src/common/loader/loader';
import ConfirmModal from '@common/src/common/modal/confirmModal';
import RefundModal from './refundModal';
import { initiateRefund, updateOrder } from '@common/src/service/cloud/order';
import { useToast } from '@common/src/common/Toast/ToastProvider';
import { getOrderHistory, getPaymentHistory } from '@hub/service/firebase/order';

const OrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [paymentLoader, setPaymentLoader] = useState(true);
  const [orderLoader, setOrderLoader] = useState(true);
  const [status, setStatus] = useState({});
  const [confirm, setConfirm] = useState({ show: false, type: '' });
  const [refundModal, setRefundModal] = useState(false);
  const [modalLoaders, setModalLoaders] = useState({ confirm: false, refund: false });
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get('orderId');
  const toast = useToast();
  const [reason, setReason] = useState("")


  const { data, loading, error, refetch } = useQuery(GET_ORDER_DETAILS, {
    variables: { id: orderId },
  });

  useEffect(() => {
    fetchOrderHistory()
    fetchPaymentHistory()
    if (!loading && !error) {
      setOrderDetails(data.orderQuery || {});
    }
  }, [data, loading, error]);
  const onClose = () => {
    setModalLoaders({ confirm: false, refund: false })
    setConfirm({ show: false, type: '' })
    setRefundModal(false);
    setReason('')
  }
  const fetchOrderHistory = async () => {
    try {
      let orderHistoryArr = await getOrderHistory(orderId)
      setOrderHistory(orderHistoryArr)
      setOrderLoader(false)
    } catch (error) {
      setOrderLoader(false)
      console.error('Error retrieving order history:', error);
      return [];
    }
  };
  const fetchPaymentHistory = async () => {
    try {
      let paymentHistoryArr = await getPaymentHistory(orderId)
      setPaymentHistory(paymentHistoryArr)
      setPaymentLoader(false)
    } catch (error) {
      setPaymentLoader(false)
      console.error('Error retrieving order history:', error);
      return [];
    }
  };
  const onConfirm = () => {

    if (status?.value === ORDER_STATUS_LIST.CANCELLED) {
      setConfirm({ show: false, type: '' })
      setModalLoaders({ ...modalLoaders, confirm: false })
      setRefundModal(true)
    } else {
      setModalLoaders({ ...modalLoaders, confirm: true })
      triggerUpdateStatus();
    }
  }
  const triggerUpdateStatus = async () => {
    try {
      let updateArr = {
        id: orderDetails?.id,
        orderId: orderId,
        orderStatus: status.value,
        customerId: orderDetails?.user?.id,
        reason
      }
      await updateOrder(updateArr);
      onClose();
      refetch({ variables: { id: orderId } })
      toast.success('Order status updated successfully');
    } catch (error) {
      onClose();
      console.error('Error updating order status:', error);
    }
  }
  const triggerUpdateWithRefund = async () => {
    try {
      if (orderDetails?.paymentStatus !== ORDER_STATUS_LIST.PAID || orderDetails?.paymentMode !== "ONLINE") {
        toast.error('You can not refund orders that are not paid/online.')
        return
      }
      setModalLoaders({ ...modalLoaders, refund: true })
      const result = await initiateRefund({
        id: orderDetails?.id,
        orderId: orderId,
        orderStatus: status.value,
        customerId: orderDetails?.user?.id,
        reason
      });
      if (result) {
        onClose();
        toast.success('Refund initiated successfully');
      }
    } catch (error) {
      onClose();
      console.error('Error in intiate refund:', error);
    }
  }
  const triggerUpdateWithOutRefund = () => {
    setModalLoaders({ ...modalLoaders, confirm: true })
    triggerUpdateStatus()
  }


  let STATUS_OPTIONS = (orderDetails?.service === SERVICES.DELIVERY || orderDetails?.service === SERVICES.GLOBAL_DELIVERY) ? ORDER_STATUS_DELIVERY_OPTIONS : ORDER_STATUS_TAKE_AWAY_OPTIONS
  if (loading) return <Loader />;
  let currency = orderDetails?.meta?.config?.currency?.currencyCode

  return (
    <div className="order-details">
      <ConfirmModal
        title="Are you sure?"
        message={
          `Do you want to change the ${confirm.type === 'order'
            ? `order status from ${orderDetails?.orderStatus}`
            : 'payment status'} to ${status.label}?`
        }
        btnTxt="Confirm"
        isOpen={confirm}
        loading={modalLoaders.confirm}
        setIsOpen={setConfirm}
        onClose={onClose}
        action={() => onConfirm()}
      />
      <RefundModal
        isOpen={refundModal}
        setIsOpen={setRefundModal}
        loading={{ confirm: modalLoaders.confirm, refund: modalLoaders.refund }}
        message={`Choose the cancellation refund method for order ${orderDetails?.orderNumber}.`}
        action={() => triggerUpdateWithOutRefund()}
        refundAction={() => triggerUpdateWithRefund()}
        reason={reason}
        onClose={onClose}
        setReason={setReason}
      />
      {orderDetails ? (
        <div>
          {orderDetails && (
            <div className="mt-6 min-h-[calc(100vh-150px)]">
              <div className="box">
                <div className='box-body flex justify-between items-center'>
                  <div className="flex items-center">
                    <h2 className="mr-4">Order : {orderDetails.orderNumber}</h2>
                    <h2>
                      <span className={`badge text-lg px-4 py-2 ${getOrderStatusColor(orderDetails.orderStatus)}`}>
                        {ORDER_STATUS_LIST[orderDetails.orderStatus]}
                      </span>
                    </h2>
                  </div>
                  <SelectBox
                    name="orderStatus"
                    label="Order Status"
                    value={
                      STATUS_OPTIONS.find((option) => option.value === orderDetails.orderStatus)
                    }
                    onChange={(e) => {
                      setStatus(e);
                      setConfirm({ show: true, type: 'order' })
                    }}
                    className='w-[200px]'
                    options={STATUS_OPTIONS}
                  />
                </div>
              </div>

              <div className="mt-6 grid grid-cols-2 gap-6">
                <div>
                  <div className='box'>
                    <div className="box-body">
                      <h5>Order Summary</h5>
                      <ul className="mt-6 grid grid-cols-1 gap-6">
                        {orderDetails?.products?.map((product) => (
                          <li key={product.id} className="flex items-center">
                            <img src={product.product?.image} alt={product.product?.name?.en} className="w-[50px] h-[50px] mr-4" />
                            <div>
                              <p className="font-bold">{product.product?.name?.en}</p>
                              <p className="text-sm">{product.quantity} x {product.unitPrice}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-6">
                        <p><strong>Delivery Charge:</strong> {currency} {orderDetails.deliveryCharge}</p>
                        <p><strong>Discount:</strong> {currency} {orderDetails.discountedPrice}</p>
                        <p><strong>Subtotal:</strong> {currency} {orderDetails.subtotal}</p>
                        <p><strong>Total:</strong> {currency} {orderDetails.total}</p>
                      </div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className="box-body">
                      <h5>Order Type</h5>
                      <div className="mt-6">
                        <h4 className="text-orange-500">{orderDetails.service}</h4>
                        {(orderDetails.service === SERVICES.DELIVERY || orderDetails.service === SERVICES.GLOBAL_DELIVERY) && (
                          <>
                            <h6 className='mt-4'>Delivery Address</h6>
                            {orderDetails?.address?.name}<br />
                            {orderDetails?.address?.formatted}<br />
                            {orderDetails?.address?.houseNumber}<br />
                            {orderDetails?.address?.apartment}<br />
                            {orderDetails?.address?.direction}<br />
                            {orderDetails?.address?.phoneCode}
                            {orderDetails?.address?.phone}
                          </>

                        )}
                        {(orderDetails.service === SERVICES.TAKEAWAY || orderDetails.service === SERVICES.GLOBAL_TAKEAWAY) && (
                          <>
                            <h6 className='mt-4'>Store Details</h6>
                            <strong>OUTLET CODE: </strong>{orderDetails?.store?.outeletCode}<br />
                            {orderDetails?.store?.address?.address?.en}<br />
                            <strong>OPEN:</strong>{orderDetails?.store?.open}
                            <strong>CLOSE:</strong>{orderDetails?.store?.close}
                          </>

                        )}
                      </div>
                    </div>
                  </div>

                  <div className='box' >
                    <div className='box-body'>
                      <h5>Customer Information</h5>
                      <div className="mt-6">
                        <p> {orderDetails.user?.firstName} {orderDetails.user?.lastName}</p>
                        <p> {orderDetails?.address?.formatted}</p>
                        <p> {orderDetails.user?.email}</p>
                        <p> {orderDetails.user?.phoneNumber}</p>
                      </div>
                    </div>
                  </div>
                  <div className='box' >
                    <div className='box-body flex justify-between'>
                      <div>
                        <h5>Payment Details</h5>
                        <div className="mt-6">
                          Payment Type:<h5>{orderDetails.paymentMode}</h5>
                          {orderDetails?.paymentStatus &&
                            <p className='mt-3'>
                              <strong>Payment Status:</strong>
                              <span className={`ml-3 badge ${getOrderStatusColor(ORDER_STATUS_LIST[orderDetails?.paymentStatus])}`}>
                                {ORDER_STATUS_LIST[orderDetails?.paymentStatus]}
                              </span>
                            </p>
                          }
                        </div>
                      </div>
                      {orderDetails.paymentStatus !== ORDER_PAYMENT_STATUS[1].value && (
                        <div className='mt-6'>
                          <SelectBox
                            options={orderDetails.paymentMode === PAYMENT_MODES.COD ? ORDER_PAYMENT_STATUS_COD : ORDER_PAYMENT_STATUS}
                            label="Payment Status"
                            value={(orderDetails.paymentMode === PAYMENT_MODES.COD ? ORDER_PAYMENT_STATUS_COD : ORDER_PAYMENT_STATUS).find((option) => option.value === orderDetails.orderStatus)}
                            onChange={(e) => {
                              setStatus(e);
                              setConfirm({ show: true, type: 'payment' })
                            }}
                            className='w-[200px]'
                          />
                        </div>
                      )}

                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  {[
                    { title: 'Order History', data: orderHistory, loader: orderLoader, type: 'ORDER' },
                    { title: 'Payment History', data: paymentHistory, loader: paymentLoader, type: 'PAYMENT' },
                  ].map((section) => (
                    <div key={section.title} className="box mb-6">
                      <div className="box-body">
                        <h5>{section.title}</h5>
                        <div className="mt-6 min-h-[200px]">
                          {section.loader ? <LoaderSm /> : (
                            section.data.map((item) => (
                              <div key={item.id} className="box mt-4 border-solid border-gray-300 py-4">
                                <div className="box-body !pb-0 !pt-0">
                                  <div className="flex items-center mb-2">
                                    <i className="ri-calendar-event-line text-success text-2xl mr-2"></i>
                                    <div className="flex-1">
                                      <h6>{moment.unix(item.createdAt?.seconds).add(item.createdAt?.nanoseconds / 1000000, 'seconds').format('DD-MM-yyyy hh:mm A')}</h6>
                                    </div>
                                    <div>
                                      <h6>
                                        <span className={`badge ${getOrderStatusColor(ORDER_STATUS_LIST[item.status])}`}>
                                          {ORDER_STATUS_LIST[item.status]}
                                        </span>
                                      </h6>
                                    </div>
                                  </div>
                                  <p>{item?.reason}</p>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <EmptyData icon="shopping-cart-line" text="Order details not found" />
      )}
    </div>
  );
};

export default OrderDetails;

import { put, takeLatest } from 'redux-saga/effects'
import { fetchProductsFailure, FETCH_PRODUCTS } from './actions'

function* fetchProductsSaga() {
  try {
    console.log('fetchProductsSaga')
    //  const response = yield call(fetchProductsApi);  // Assume an API call to fetch products
    //  yield put(fetchProductsSuccess(response.data));  // Dispatch success action
  } catch (error) {
    yield put(fetchProductsFailure(error.message)) // Dispatch failure action
  }
}

export function* watchFetchProducts() {
  yield takeLatest(FETCH_PRODUCTS, fetchProductsSaga)
}

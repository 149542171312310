import { Formik, Form, Field, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SelectBox from '@common/src/common/selectbox/selectbox';
import * as Yup from 'yup';
import AddProductButton from '../../../components/common/buttons/AddProductButton';
import PropTypes from 'prop-types';
import Toggle from '@common/src/common/Toggle/Toggle';
import { useState } from 'react';
import { DAY_ABBREVIATIONS, DAY_OPTIONS, PRODUCT_ADD } from '@hub/constants/constants';
import { normalizeDateFormat } from '@common/src/common/helper';

const validationSchema = Yup.object().shape({
  dateStart: Yup.date().required('Start date is required'),
  dateEnd: Yup.date().required('End date is required'),
  timeStart: Yup.string().required('Start time is required'),
  timeEnd: Yup.string().required('End time is required'),
  days: Yup.array().min(1, 'At least one day is required'),
});

const Availability = ({ handleNext, handleBack, setProductData, productData, isEdit, setEditProductData, editProductData }) => {
  const [timed, setTimed] = useState(productData?.timed || false);

  const handleSubmit = (values) => {
    // Determine the day option
    let dayOption;
    if (values?.daysAll) {
      dayOption = PRODUCT_ADD.ALL;
    } else if (values.days.length === 7) {
      dayOption = PRODUCT_ADD.ALL;
    } else {
      dayOption = PRODUCT_ADD.SOME;
    }

    // Convert selected days to abbreviations
    const selectedDays = values.days.map(day => DAY_ABBREVIATIONS[day]);
  
    // Construct the availability object
    const availability = timed  && {
      date: {
        start: normalizeDateFormat(values.dateStart),
        end: normalizeDateFormat(values.dateEnd),
        option: values.dateAll ? PRODUCT_ADD.ALL : PRODUCT_ADD.RANGE,
      },
      day: {
        days: selectedDays,
        option: dayOption,
      },
      time: {
        start: values.timeStart,
        end: values.timeEnd,
        option: values.timeAll ? PRODUCT_ADD.ALL : PRODUCT_ADD.RANGE,
      },
    };
    const updatedData = { ...productData, availability, timed };
    if (isEdit) {
      setEditProductData({ availability, ...editProductData, timed });
      setProductData((prevData) => ({ ...prevData, ...updatedData }));
    } else {
      setProductData(updatedData);
    }

    handleNext();
  };

  return (
    <div className="grid grid-cols-12 gap-6 ">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <Formik
                initialValues={{
                  dateStart: productData?.availability?.date?.start || null,
                  dateEnd: productData?.availability?.date?.end || null,
                  days:
                    productData?.availability?.day?.days?.map((day) =>
                      Object.keys(DAY_ABBREVIATIONS).find((key) => DAY_ABBREVIATIONS[key] === day)
                    ) || [],
                  timeStart: productData?.availability?.time?.start || null,
                  timeEnd: productData?.availability?.time?.end || null,
                  timed: productData?.timed || false,
                  dateAll: productData?.availability?.date?.option === PRODUCT_ADD.ALL,
                  daysAll: productData?.availability?.day?.option === PRODUCT_ADD.ALL,
                  timeAll: productData?.availability?.time?.option === PRODUCT_ADD.ALL,
                }}
                validationSchema={timed && validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleSubmit(values)
                  setSubmitting(false)
                }}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form>
                    {/* Timed toggle */}
                    <div className="xl:col-span-4 col-span-12">
                      <Toggle
                        label="Timed"
                        name="timed"
                        value={timed}
                        onChange={(e) => {
                          setFieldValue('timed', e.target.checked)
                          setTimed(e.target.checked)
                        }}
                      />
                    </div>

                    {timed && (
                      <>
                        {/* Date Range */}
                        <div className="xl:col-span-12 col-span-12">
                          <label className="form-label" htmlFor="date">
                            Date Range <span style={{ color: 'red' }}>*</span>
                          </label>
                          <div className="flex space-x-4">
                            <div className="w-full">
                              <DatePicker
                                selected={values.dateStart}
                                onChange={(date) => setFieldValue('dateStart', date)}
                                selectsStart
                                startDate={values.dateStart}
                                endDate={values.dateEnd}
                                placeholderText="Start Date"
                                className="form-control w-full !rounded-md"
                                dateFormat="dd/MM/yyyy"
                              />
                              <ErrorMessage name="dateStart" component="div" style={{ color: 'red' }} />
                            </div>
                            <div className="w-full">
                              <DatePicker
                                selected={values.dateEnd}
                                onChange={(date) => setFieldValue('dateEnd', date)}
                                selectsEnd
                                startDate={values.dateStart}
                                endDate={values.dateEnd}
                                minDate={values.dateStart}
                                placeholderText="End Date"
                                className="form-control w-full !rounded-md"
                                dateFormat="dd/MM/yyyy"
                              />
                              <ErrorMessage name="dateEnd" component="div" style={{ color: 'red' }} />
                            </div>
                            <button type="button" onClick={() => setFieldValue('dateAll', !values.dateAll)}>
                              {PRODUCT_ADD.ALL}
                            </button>
                          </div>
                        </div>

                        {/* Days Selection */}
                        <div className="xl:col-span-4 col-span-12">
                          <SelectBox
                            label="Select Day(s)"
                            name="days"
                            options={DAY_OPTIONS}
                            isMulti={true}
                            value={DAY_OPTIONS.filter((option) => values.days.includes(option.value))}
                            onChange={(e) =>
                              setFieldValue(
                                'days',
                                e.map((day) => day.value)
                              )
                            }
                            placeholder="Select Days"
                            required={true}
                          />
                          <ErrorMessage name="days" component="div" style={{ color: 'red' }} />
                          <button type="button" onClick={() => setFieldValue('daysAll', !values.daysAll)}>
                            {PRODUCT_ADD.ALL}
                          </button>
                        </div>

                        {/* Time Range */}
                        <div className="xl:col-span-4 col-span-12">
                          <label className="form-label" htmlFor="time">
                            Time Range <span style={{ color: 'red' }}>*</span>
                          </label>
                          <div className="flex space-x-4">
                            <div className="w-full">
                              <Field type="time" name="timeStart" className="form-control w-full !rounded-md" />
                              <ErrorMessage name="timeStart" component="div" style={{ color: 'red' }} />
                            </div>
                            <div className="w-full">
                              <Field type="time" name="timeEnd" className="form-control w-full !rounded-md" />
                              <ErrorMessage name="timeEnd" component="div" style={{ color: 'red' }} />
                            </div>
                            <button type="button" onClick={() => setFieldValue('timeAll', !values.timeAll)}>
                              {PRODUCT_ADD.ALL}
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    <AddProductButton handleBack={handleBack} />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

Availability.propTypes = {
  handleNext: PropTypes.func.isRequired,
  setProductData: PropTypes.func.isRequired,
  productData: PropTypes.object,
  isEdit: PropTypes.bool,
  setEditProductData: PropTypes.func,
  editProductData: PropTypes.object,
  handleBack: PropTypes.func
};

export default Availability;
import { CLOUD_FUNCTIONS } from '../../constants/constants'
import { callCloudFunction } from './cloud'

export const initiateRefund = async (data) => {
    return await callCloudFunction(CLOUD_FUNCTIONS.INITIATE_REFUND, data)
}


export const updateOrder = async (data) => {
    return callCloudFunction( CLOUD_FUNCTIONS.UPDATE_ORDER, data)
  }
// users.js
import PropTypes from 'prop-types'
import logger from '@common/src/lib/logger'
import { db } from '../../firebase/firebaseapi'
import { collection, doc, getDoc, getDocs, limit, query, serverTimestamp, updateDoc, where } from 'firebase/firestore'
import { COLLECTIONS } from '../../constants/constants'
import { STATUS, USER_TYPES } from '@common/src/constants/constants'
import { isHub } from '@common/src/common/helper'

export const getUser = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, COLLECTIONS.USERS, uid))
    if (userDoc.exists()) {
      const userData = userDoc.data()
      return userData
    } else {
      logger.log('No such user document found!')
    }
  } catch (error) {
    logger.error('Error fetching user data or roles:', error)
  }
}
export const activateUser = async (email) => {
  try {
    const userRef = collection(db, COLLECTIONS.USERS)
    const q = query(
      userRef,
      where('email', '==', email),
      where('status', '==', STATUS.PENDING),
      where('userType', '==', isHub() ? USER_TYPES.HUB_SUB_ADMIN : USER_TYPES.PULSE_SUB_ADMIN),
      limit(1)
    )
    const querySnapshot = await getDocs(q)
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((doc) => {
        updateDoc(doc.ref, { status: STATUS.ACTIVE, updatedAt: serverTimestamp(), joinedAt: serverTimestamp() })
      })
    } else {
      logger.log('No such user document found!')
    }
  } catch (error) {
    logger.error('Error activating user:', error)
  }
}

getUser.propTypes = {
  uid: PropTypes.string.isRequired,
}

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_ORDERS } from '@common/src/lib/graphql/query/orders';
import OrderTable from './orderTable';
import Loader from '@common/src/common/loader/loader';
import PropTypes from 'prop-types';

const AllOrders = ({ company, stores }) => {
    const [selectedStores, setSelectedStores] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [filterStores, setFilterStores] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [selectedFilterStatusValue, setSelectedFilterStatusValue] = useState([]);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const { data,loading, error } = useQuery(GET_ALL_ORDERS, {
        variables: {
            search: searchQuery || undefined,
            filters: {
                companyId: company.value ? company.value.toString() : undefined,
                storeId: filterStores.length ? filterStores : undefined,
                orderStatus:filterStatus || undefined,
                startDate:filterStartDate || undefined,
                endDate:filterEndDate || undefined
            }
        }
    });

    useEffect(() => {
        if (!loading && !error) {
            setAllOrders(data.orderListQuery.rows);
        }
    }, [data, loading, error]);

    // Function to handle the search term
    const handleSearchChange = (newSearchItem) => {
        setSearchQuery(newSearchItem);
        };

    const onAllFilter = (selectedStartDate,selectedEndDate,selectedStatus,storesSelected) => {
        
        // Clear all filters
        setFilterStores([]);
        setSelectedStores([]);
        setFilterStatus('');
        setSelectedFilterStatusValue([]);
        setFilterStartDate('');
        setFilterEndDate('');

        if (storesSelected?.value) {
            setFilterStores([storesSelected?.value?.toString() || " "]);
            setSelectedStores(storesSelected)
        }
        if (selectedStatus?.value) {
            setFilterStatus(selectedStatus?.value)
            setSelectedFilterStatusValue(selectedStatus)
        }
        if(selectedStartDate !== ''){
            console.log('selectedStartDate', selectedStartDate)
            setFilterStartDate(selectedStartDate)
        }
        if(selectedEndDate !== ''){
            setFilterEndDate(selectedEndDate)
        }
    }

    if (loading) return <Loader />
    return (
        <div className="mt-6 table-responsive">
            <OrderTable
                onFilter={onAllFilter}
                stores={stores}
                selectedStores={selectedStores}
                orders={allOrders}
                searchQuery={handleSearchChange}
                searchData={searchQuery}
                selectedFilterStatus={selectedFilterStatusValue}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
            />
        </div>
    )
}
AllOrders.propTypes = {
    stores: PropTypes.array,
    company: PropTypes.object,
}
export default AllOrders;

import React from 'react';
import PropTypes from 'prop-types';
import placeholderImg from '@hub/assets/images/image_not_found.svg'
const AvatarImage = ({ src }) => (
    <div className="avatar avatar-md avatar-rounded relative">
        <img src={src || placeholderImg} alt={src || 'no_image'} className="hidden"
            onLoad={
                (e) => {
                    // remove hidden class when image is loaded and remove spinner
                    e.target.classList.remove('hidden');
                    const parent = e.target.parentElement;
                    const imgSpin = parent.querySelector('.img-spin');
                    if (imgSpin) parent.removeChild(imgSpin);
                }
            } />
        <div className="absolute inset-0 flex items-center justify-center img-spin">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
        </div>
    </div>
);
AvatarImage.propTypes = {
    src: PropTypes.string,
}
export default AvatarImage;

import {
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  SET_SELECTED_COMPANY,
  RESET_USER
} from './action'

const initialState = {
  user: null,
  permissions: [],
  companies: [],
  selectedCompany: null,
  loading: false,
  error: null,
  menuItems: [],
  isNotLogged: true
}
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        isNotLogged: false,
        loading: true,
        error: null,
      }

    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        user: action.payload.userData,
        permissions: action.payload.permissions,
        companies: action.payload.companies,
        menuItems: action.payload.menuItems,
        isNotLogged: false,
        loading: false,
        error: null,
      }

    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        isNotLogged: true,
        loading: false,
        error: action.error,
      }

    case SET_SELECTED_COMPANY:
      localStorage.setItem('selectedCompany', JSON.stringify(action.company))
      return {
        ...state,
        selectedCompany: action.company,
      }
    case RESET_USER:
      return initialState;
    default:
      return state
  }
}

const initialState = {
  lang: 'en',
  dir: 'ltr',
  class: 'light',
  dataMenuStyles: 'dark',
  dataNavLayout: 'vertical',
  dataHeaderStyles: 'light',
  dataVerticalStyle: 'overlay',
  toggled: '',
  dataNavStyle: '',
  horStyle: '',
  dataPageStyle: 'regular',
  dataWidth: 'fullwidth',
  dataMenuPosition: 'fixed',
  dataHeaderPosition: 'fixed',
  loader: 'disable',
  iconOverlay: '',
  colorPrimaryRgb: '',
  colorPrimary: '',
  bodyBg: '',
  Light: '',
  darkBg: '',
  inputBorder: '',
  bgImg: '',
  iconText: '',
  body: {
    class: '',
  },
}

export default function themeReducer(state = initialState, action) {
  const { type, payload } = action

  if (type === 'THEME_CHANGER') {
    // Changed to uppercase
    return {
      ...state,
      ...payload, // Ensure this payload only contains the properties that need to be updated
      body: {
        ...state.body, // Safeguard nested objects
        ...payload.body,
      },
    }
  }
  return state
}

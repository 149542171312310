import React, { useEffect, useState } from 'react'
import Pageheader from '@common/src/pageheader/pageheader'
import LiveOrders from './tabContents/liveOrders'
import AllOrders from './tabContents/allOrders'
import DisputeOrders from './tabContents/disputeOrders'
import { useSelector } from 'react-redux'
import { getStoresByCompany } from '@common/src/service/firebase/api'

const AppOrders = () => {
    const [stores, setStores] = useState([]);
    const { selectedCompany } = useSelector((state) => state.auth)
    const [tab, setTab] = useState({ type: 'live' })


    // Update the tab based on the hash in the URL
    useEffect(() => {
        const hash = window.location.hash.replace('#', ''); // Remove the # symbol
        if (hash) {
            setTab({ type: hash });
        }
    }, []);

    // Fetch stores
    useEffect(() => {
        getStoresByCompany(selectedCompany?.value).then(res => {
            setStores(res);
        })
    }, [selectedCompany])

    // Handle tab change and update the URL hash
    const handleTabChange = (type) => {
        setTab({ type });
        window.location.hash = type; // Update the URL hash
    };
    return (
        <>
            <Pageheader currentpage="App Orders" activepage="App Orders" mainpage="Order Management" />
            <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-12 col-span-12">
                    <div className="box">
                        <div className="box-body">
                            <div className="flex items-center justify-between w-full border-b border-[#ddd]">
                                <div className="tabs w-full flex justify-between">
                                    <button
                                        className={`w-full p-6 flex items-center justify-center ${tab.type === 'live' ? 'tab-active bg-primary/20' : ''}`}
                                        onClick={() => handleTabChange('live')}>

                                        <b><span className={`bg-danger inline-block rounded-full h-3 w-3 mr-2`}> </span> LIVE ORDERS</b>
                                    </button>
                                    <button
                                        className={`w-full p-6 ${tab.type === 'all' ? 'tab-active bg-primary/20' : ''}`}
                                        onClick={() => handleTabChange('all')}>
                                        <b>ALL ORDERS</b>
                                    </button>
                                    <button
                                        className={`w-full p-6 ${tab.type === 'dispute' ? 'tab-active bg-primary/20' : ''}`}
                                        onClick={() => handleTabChange('dispute')}>
                                        <b>DISPUTES</b>
                                    </button>
                                </div>
                            </div>
                            <div className="box-content">
                                {tab.type === 'live' && <LiveOrders stores={stores} company={selectedCompany} />}
                                {tab.type === 'all' && <AllOrders stores={stores} company={selectedCompany} />}
                                {tab.type === 'dispute' && <DisputeOrders stores={stores} company={selectedCompany} />}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppOrders


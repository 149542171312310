import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import Loader from '@common/src/common/loader/loader'
import { setSelectedCompany } from '../../../redux/modules/auth/action'
import { USER_TYPES } from '@common/src/constants/constants'

const CompanyDrop = () => {
  const dispatch = useDispatch()
  const { companies = [], loading = true, user, selectedCompany } = useSelector((state) => state.auth || {})

  const onChange = (obj) => {
    dispatch(setSelectedCompany(obj))
  }
  return (
    <>
      {loading && <Loader />}
      {!loading && user?.userType === USER_TYPES.HUB_SUB_ADMIN && (
        <Select
          className="ti-form-select rounded-sm !p-0"
          placeholder="Choose Company"
          options={companies}
          defaultValue={selectedCompany || companies?.[0]}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default CompanyDrop

import React, { useState, useEffect } from 'react';
import OrderTable from './orderTable';
import { GET_ALL_ORDERS } from '@common/src/lib/graphql/query/orders';
import { ORDER_STATUS_LIST } from './common';
import { useQuery } from '@apollo/client';
import Loader from '@common/src/common/loader/loader';
import PropTypes from 'prop-types';
const DisputeOrders = ({ company, stores }) => {
    const [selectedStores, setSelectedStores] = useState([]);
    const [disputeOrders, setDisputeOrders] = useState([]);
    const [filterStores, setFilterStores] = useState([]);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const { data, loading, error } = useQuery(GET_ALL_ORDERS, {
        variables: {
            filters: {
                orderStatus: [ORDER_STATUS_LIST.FAILED, ORDER_STATUS_LIST.CANCELLED],
                companyId: company.value ? company.value.toString() : undefined,
                storeId: filterStores.length ? filterStores : undefined,
                startDate:filterStartDate || undefined,
                endDate:filterEndDate || undefined
            }
        }
    });
    useEffect(() => {
        if (!loading && !error) {
            setDisputeOrders(data.orderListQuery.rows);
        }
    }, [data, loading, error]); const handleSearchChange = (newSearchItem) => {
        setSearchQuery(newSearchItem);
        };

    const onAllFilter = (selectedStartDate,selectedEndDate,selectedStatus,storesSelected) => {
        
        // Clear all filters
        setFilterStores([]);
        setSelectedStores([]);
        setFilterStartDate('');
        setFilterEndDate('');

        if (storesSelected?.value) {
            setFilterStores([storesSelected?.value?.toString() || " "]);
            setSelectedStores(storesSelected)
        }
        if(selectedStartDate !== ''){
            console.log('selectedStartDate', selectedStartDate)
            setFilterStartDate(selectedStartDate)
        }
        if(selectedEndDate !== ''){
            setFilterEndDate(selectedEndDate)
        }
    }
    if (loading) return <Loader />
    return (
        <div className="mt-6 table-responsive">
            <OrderTable
                orders={disputeOrders}
                stores={stores}
                onFilter={onAllFilter}
                searchQuery={handleSearchChange}
                searchData={searchQuery}
                selectedStores={selectedStores}
                disputeOrder={true}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
            />
        </div>
    )
};

DisputeOrders.propTypes = {
    company: PropTypes.object,
    stores: PropTypes.array
}
export default DisputeOrders;

import { call, put, takeLatest } from 'redux-saga/effects'
import {
  FETCH_USER_DETAILS_REQUEST,
  fetchUserDetailsFailure,
  fetchUserDetailsSuccess,
  setSelectedCompany,
} from './action'
import { MENU, ROLE, USER_TYPES } from '@common/src/constants/constants'
import { MENUITEMS } from '../../../shared/components/sidebar/sidemenu/sidemenu'
import { getUser } from '../../../service/firebase/user'
import { getCompany } from '../../../service/firebase/company'
import { getRole } from '../../../service/firebase/roles'

const filterMenuItems = (menuItems, permissions) => {
  const allowedKeys = new Set(permissions)
  const filterItems = (items) => {
    return items.reduce((acc, item) => {
      if (item.type === MENU.TYPE_LINK) {
        if (allowedKeys.has(item.key)) {
          acc.push(item)
        }
      } else if (item.type === MENU.TYPE_SUB && item.children) {
        const filteredChildren = filterItems(item.children)
        if (filteredChildren.length > 0) {
          acc.push({
            ...item,
            children: filteredChildren,
          })
        }
      }
      return acc
    }, [])
  }

  return filterItems(menuItems)
}

function* fetchUserDetailsSaga(action) {
  try {
    const { uid } = action
    const user = yield call(getUser, uid)
    if (!user) {
      yield put(fetchUserDetailsFailure('No user data found!'))
      return
    }
    let permissions
    let companies = []

    if (user.userType === USER_TYPES.HUB_ADMIN) {
      permissions = [
        {
          roleData: {
            permissions: ['dashboard', 'accounts', 'companies', 'store']
          }
        }
      ]
      companies = []
    } else {
      permissions = yield call(getRole, uid)
      const hubRole = permissions.find((perm) => perm.roleData?.type === ROLE.ROLE_TYPE_HUB)
      companies = yield call(getCompany, hubRole?.allCompanies, hubRole?.companies)
    }
    let menuItems = MENUITEMS

    if (permissions !== 'all') {
      const userPermission = [...new Set(permissions.map((p) => p.roleData.permissions).flat())]
      menuItems = filterMenuItems(MENUITEMS, userPermission)
      permissions = userPermission
    }
    if (user.userType === USER_TYPES.HUB_SUB_ADMIN) {
      let selectedCompany =
        localStorage.getItem('selectedCompany') === null
          ? companies[0]
          : companies.find((c) => c.value === JSON.parse(localStorage.getItem('selectedCompany')).value) ||
          JSON.parse(localStorage.getItem('selectedCompany'))

      // also check selected company value is in companies array otherwise take first one
      if (!companies.find((c) => c.value === selectedCompany.value)) {
        selectedCompany = companies[0]
      }
      yield put(setSelectedCompany(selectedCompany))
    }
    yield put(fetchUserDetailsSuccess(user, permissions, companies, menuItems))
  } catch (error) {
    yield put(fetchUserDetailsFailure(error.message))
  }
}

function* watchFetchUser() {
  yield takeLatest(FETCH_USER_DETAILS_REQUEST, fetchUserDetailsSaga)
}

export default watchFetchUser

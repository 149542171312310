import { collection, query, where, getDocs } from 'firebase/firestore'
import { db } from '../../../../src/firebase/firebaseapi'
import { COLLECTIONS } from '../../constants/constants'
export const getMasterPermission = async () => {
  try {
    const permissionsQuery = query(collection(db, COLLECTIONS.MASTER_PERMISSIONS), where('inherit', '==', true))
    const querySnapshot = await getDocs(permissionsQuery)
    const permissions = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    return permissions
  } catch (error) {
    console.error('Error fetching master permission data:', error)
  }
}

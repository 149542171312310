import PropTypes from 'prop-types'

const Permission = ({ permissionArray, permissions }) => {
  return (
    <>
      {permissionArray?.map((permission) => {
        const relatedRole = permissions?.find((role) => role.value.includes(permission))
        const permissionName = relatedRole ? relatedRole.label : permission
        return (
          <span key={permissionName} className={`badge bg-success/10 text-success`}>
            {permissionName}
          </span>
        )
      })}
    </>
  )
}
Permission.propTypes = {
  permissionArray: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.string).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
}
export default Permission

import console from '@common/src/lib/logger'
import { httpsCallable } from 'firebase/functions'
import { CALLBLE_HEADER } from '../../constants/constants'
import { functions } from '@hub/firebase/firebaseapi'

export const callCloudFunction = async (functionName, data) => {
  try {
    const callableFunction = httpsCallable(functions, functionName)
    const res = await callableFunction({
      body: data,
      ...CALLBLE_HEADER,
    })

    if (res?.data?.code === 200) {
      return Promise.resolve(res?.data?.data)
    } else {
      console.log(res)
      return Promise.reject(res?.data)
    }
  } catch (error) {
    console.error(`Error calling ${functionName}:`, error)
    throw error
  }
}

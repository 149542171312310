import React, { createContext, useContext, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'
import { toastReducer } from './toastReducer'
import ToastsContainer from './ToastContainer'

const initialState = {
  toasts: [],
}
// Create the context
const ToastContext = createContext()

// Custom hook to use toast
export const useToast = () => useContext(ToastContext)

// Toast Provider to wrap around the app
export const ToastProvider = ({ children }) => {
  const [state, dispatch] = useReducer(toastReducer, initialState)

  // Function to show toast
  const addToast = (message, type = 'success') => {
    const id = Date.now()
    dispatch({ type: 'ADD_TOAST', payload: { id, message, type } })
  }

  const remove = (id) => {
    dispatch({ type: 'DELETE_TOAST', payload: id })
  }
  const success = (message) => {
    addToast(message, 'success')
  }

  const warning = (message) => {
    addToast(message, 'warning')
  }

  const info = (message) => {
    addToast(message, 'info')
  }

  const error = (message) => {
    addToast(message, 'danger')
  }

  const value = useMemo(() => ({ success, warning, info, error, remove }), [success, warning, info, error, remove])

  return (
    <ToastContext.Provider value={value}>
      <ToastsContainer toasts={state.toasts} />
      {children}
    </ToastContext.Provider>
  )
}
ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

const Tree = ({ data, values = [], onChange }) => {
  const [checkedItems, setCheckedItems] = useState(values)
  const memoizedData = useMemo(() => data, [data])

  const handleCheckChange = useCallback((role, isChecked) => {
    setCheckedItems((prev) => {
      const updatedCheckedItems = isChecked ? [...prev, role.value] : prev.filter((item) => item !== role.value)
      onChange?.(updatedCheckedItems)
      return updatedCheckedItems
    })
  })

  return (
    <>
      {memoizedData?.map((role) => (
        <div className="form-check form-check-md" key={role.value}>
          <input
            className="form-check-input"
            type="checkbox"
            id={`flexCheck-${role.value}`}
            checked={checkedItems.includes(role.value)}
            onChange={(e) => handleCheckChange(role, e.target.checked)}
          />
          <label className="form-check-label" htmlFor={`flexCheck-${role.value}`}>
            {role.label}
          </label>
        </div>
      ))}
    </>
  )
}
Tree.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  values: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func.isRequired,
}
export default Tree

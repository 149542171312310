import { gql } from '@apollo/client'

export const GET_ALL_ORDERS = gql`
  query getAllOrders($search: String, $limit: Int, $offset: Int, $filters: OrderFiltersInput) {
     orderListQuery(search: $search, limit: $limit, offset: $offset, filters: $filters) {
      limit
      offset
      totalPages
        rows{
            orderId
            orderNumber
            customer{
                firstName
                lastName
                phoneNumber
            }
            address{
             phone
            }
            orderDate
            service
            total
            paymentMode
            paymentStatus
            orderStatus
            store{
                outletCode
            }
            meta{
                config{
                    currency{
                        currency
                        currencyCode
                    }
                }
            }
        }
    }
  }
`;



export const GET_ORDER_DETAILS = gql`
    query getOrder($id: String) {
        orderQuery(id: $id) {
        id
        orderId
        orderToken
        orderNumber
        service
        createdAt
        expectText {
            ar
            en
        }

        address {
            name
            phoneCode
            phone
            houseNumber
            apartment
            direction
            addressType
            formatted
            geoData {
            coordinates {
                lat
                lng
            }
            }
        }
        user {
            id
            firstName
            firstName
            email
            phoneCode
            phoneNumber
        }
        meta {
            config {
            currency {
                currencyCode
            }
            }
        }
        orderStatus
        lang
        remarks
        loyaltyPoints {
            earnedCoins {
            coin
            value
            }
        }
        count
        subtotal
        deliveryCharge
        discountedPrice
        total
        paymentMode
        paymentStatus
        products {
            id
            itemId
            product {
            name {
                ar
                en
            }
            image
            description {
                ar
                en
            }
            }
            quantity
            unitPrice
            amount
            modifierGroups {
            id
            groupName {
                ar
                en
            }
            type
            modifiers {
                id
                quantity
                unitPrice
                amount
                modifier {
                modItemName {
                    en
                    ar
                }
                image
                foodType
                }
            }
            }
        }
    }
}`;
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

// Define the static button style function outside
const buttonStyle = (currentValue, language) => ({
  textDecoration: currentValue === language ? 'underline' : 'none',
  marginRight: '0.5rem',
  fontWeight: currentValue === language ? 'bold' : 'normal',
  color: currentValue === language ? '#1A202C' : '#718096',
})

const LanguageSwitchTab = ({ value, onChange, languages }) => {
  // Memoize the handleChange function
  const handleChange = useCallback((language) => () => onChange(language), [onChange])

  // Memoize rendering of the language tabs to avoid re-renders
  const languageButtons = useMemo(
    () =>
      Object.keys(languages)?.map((k) => (
        <li key={k}>
          <button type="button" style={buttonStyle(value, k)} onClick={handleChange(k)}>
            {languages[k].toUpperCase()}
          </button>
        </li>
      )),
    [languages, value, handleChange]
  )

  return <ul className="form-label flex mb-1">{languageButtons}</ul>
}

LanguageSwitchTab.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  languages: PropTypes.object.isRequired,
}

export default LanguageSwitchTab


import React from 'react';
import PropTypes from 'prop-types';

const ConfirmModal = ({ isOpen = false, setIsOpen, action, title, message, btnTxt, loading = false }) => {

    const toggleModal = () => {
        setIsOpen({ show: !isOpen.show, type: isOpen.type });
    };

    return (
        <div id="todo-compose" className={`hs-overlay bg-primary/20 ti-modal ${isOpen.show ? 'open' : 'hidden'}`}>

            <div className="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out fixed inset-0 z-[10000] flex items-center justify-center">
                <div className="ti-modal-content w-full max-w-[600px] mx-auto">
                    <div className="ti-modal-header">
                        <h6 className="modal-title text-[1rem] font-semibold" id="mail-ComposeLabel">{title}</h6>
                        <button type="button" className="hs-dropdown-toggle !text-[1rem] !font-semibold !text-defaulttextcolor" onClick={toggleModal}>
                            <span className="sr-only">Close</span>
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                    <div className="ti-modal-body px-4">
                        <p>{message}</p>
                    </div>
                    <div className="ti-modal-footer">
                        <button type="button" className="hs-dropdown-toggle ti-btn ti-btn-secondary-full align-middle" onClick={toggleModal}>
                            Close
                        </button>
                        <button type="button" className="ti-btn bg-primary text-white !font-medium" onClick={action} disabled={loading}>
                            {btnTxt || 'Save Changes'}
                            {loading && (
                                <span className="loading">
                                    <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

ConfirmModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    btnTxt: PropTypes.string,
    isOpen: PropTypes.object,
    loading: PropTypes.bool,
    setIsOpen: PropTypes.func.isRequired,
    action: PropTypes.func
};

export default ConfirmModal


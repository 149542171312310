import loaderImg from './loader.svg'

const Loader = () => {
  return (

    <div className='h-[calc(100vh-150px)] flex items-center justify-center'>
      <img src={loaderImg} alt=''/>
    </div>
  )
}

export default Loader
